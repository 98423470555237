import React, {useEffect, useState, useRef, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import md5 from 'js-md5';
import { useUserContext } from "../../contexts";
import { meFn, userFn, teamFn, getAssetsAvailability, getProducts, subscribeUserFn, cancelSubscription, deleteUserFn, getInvoices, Analytics} from '../../lib/api';
import '../../styles/layout.scss';
import './Member.scss';
import './Results.scss';
import { Membership } from './';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Spinner } from '../Spinner';
import { FormatSelection } from '../FormatSelection';
import { Favorites } from '../Favorites';
import { Consumptions } from '../Consumptions';
import { Input } from '../Form';
import { getReadableDate } from '../../lib/utils';
import { tab } from '@testing-library/user-event/dist/tab';
import { Modal } from '../Modal';
import { Plans } from '../Plans';
import { Questionnaire } from '../Questionnaire';

const Member = ({subscriptionSuccess=false}) => {
    //dummy padding

    //Custom Hooks
    const navigate = useNavigate();
    const {user, reloadCurrentUser, isAuthenticating} = useUserContext();

    //Ref Hooks
    const headerRef = useRef(null);
    const personalDataRef = {
        'profession': useRef(null),
        'software_preferences': useRef(null),
        'usage_purpose': useRef(null),
        'website_channel': useRef(null),
        'how_hear': useRef(null),
        'next_desire': useRef(null)
    };

    const billingDataRef = {
        'business_name': useRef(null),
        'address': useRef(null),
        'vat': useRef(null),
        'invoice_notes': useRef(null)
    }
    const newPasswordRef = useRef(null);
    const repeatPasswordRef = useRef(null);
    const plansRef = useRef(null);

    //State Hooks
    const [assetsAvailable, setAssetsAvailable] = useState(0);

    const [premiumAssetsPolicy, setPremiumAssetsPolicy] = useState(false);
    const [planDownloadsPerDay, setPlanDownloadsPerDay] = useState(0);
    const [defaultProducts, setDefaultProducts] = useState(null);
    const [selectedTab, setSelectedTab] = useState('settings');

    const [loadingGeneral, setLoadingGeneral] = useState(false);

    const [personalData, setPersonalData] = useState(null);
    const [billingData, setBillingData] = useState(null);
    const [invoices, setInvoices] = useState([]);

    const [automaticInvoice, setAutomaticInvoice] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [footagecrateBlender, setFootagecrateBlender] = useState(false);

    const [questionnaireModal, setQuestionnaireModal] = useState(false);
    const [subscriptionExpand, setSubscriptionExpand] = useState(false);
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccessModal, setCancelSuccessModal] = useState(false);
    const [applyPromoModal, setApplyPromoModal] = useState(false);

    const [passwordError, setPasswordError] = useState(null);
    const [currentPassword, setCurrentPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [passwordChangeSuccessModal, setPasswordChangeSuccessModal] = useState(false);


    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMembersLoading, setTeamMembersLoading] = useState(false);
    const [teamInvites, setTeamInvites] = useState([]);
    const [teamInvitesLoading, setTeamInvitesLoading] = useState(false);
    const [teamInvitesEmail, setTeamInvitesEmail] = useState(null);
    const [inviteError, setInviteError] = useState(null);
    const [inviteSuccess, setInviteSuccess] = useState(null);
    const [sendingInvite, setSendingInvite] = useState(false);
    const [upgradeEnterprisePlans, setUpgradeEnterprisePlans] = useState([]);

    //UseEffect Hooks
    useEffect(() => {
        //console.log("SETUSER", setUser);
        if(!user && !isAuthenticating){
            //console.log("No user logged in on member page", user);
            navigate(`/login/`, { replace: true });
            return;
        }
           
        if(isAuthenticating){
            setLoadingGeneral(true);
            return;
        }
        setLoadingGeneral(false);


        getAvailableDownloads();
        getProducts({campaign: false}).then(products => {
            //console.log("MY PRODUCTS", {products});
            setDefaultProducts(products);
        })
        .catch(e => {
            console.log("error getting products", e, {campaign: false});
        });

        Object.keys(user.settings?user.settings:{}).forEach(key => {
            
            if(['profession','software_preferences', 'usage_purpose', 'website_channel', 'how_hear', 'next_desire'].includes(key)){
                if(personalDataRef[key].current && personalDataRef[key].current.setValue){
                    personalDataRef[key].current.setValue(user.settings[key]);
                }
            }
            if(key ==='automatic_invoice'){
                setAutomaticInvoice(user.settings[key]);
            }
            if(key === 'newsletter'){
                setNewsletter(user.settings[key]);
            }
            if(key === 'footagecrateBlenderAddon'){
                setFootagecrateBlender(user.settings[key]);
            }
        });

        if(user.team && user.team.subscription && user.team.subscription.plan){
            //If there was a susbcriptio success inform to analytics
            if(subscriptionSuccess){
                //console.log("Subscription success");
                setLoadingGeneral(true);
                Analytics.goalPlan(user.team.subscription.plan);
                Analytics.conversion(user.team.subscription.plan.price, user.team.subscription.id);
                

                setTimeout(() => {
                    try{
                        document.location.href='/member';
                    }catch(e){
                        console.log("error navigating", e);
                    }
                    navigate('/member', { replace: true });
                }, 1000); 
            }

            let tempPlan = user.team.subscription.plan;
            let charges = 0;
            tempPlan.features.forEach(feat => {
                if(feat.name === 'assets-download'){
                    charges =+ feat.charges;
                    if (feat.policy && feat.policy.name.toLowerCase().includes('free')) {
                        setPremiumAssetsPolicy(false);
                    } else {
                        setPremiumAssetsPolicy(true);
                    }
                }
            });

            setPlanDownloadsPerDay(charges);

        }
    }, [user, isAuthenticating]);


    useEffect(() => {
        setInviteError(null);
        setInviteSuccess(null);
    }, [teamInvitesEmail]);

    useEffect(() => {

        if(selectedTab==="subscriptions") {
            getInvoices().then(data =>  {
                //console.log("invoices", data);
                setInvoices(data);
            });
            teamFn.settings().then(team => {
                //console.log("team", team);
                
                Object.keys(team?team:{}).forEach(key => {
                    if(['business_name', 'address', 'vat', 'invoice_notes'].includes(key)){
                        if(billingDataRef[key].current && billingDataRef[key].current.setValue){
                            billingDataRef[key].current.setValue(team[key]);
                        }
                    }
                });
            });
        }else if(selectedTab === 'team') {
            fetchTeamAndInvites();
            getProducts({campaign: 'enterprise-plus'}).then(upgradeProducts => {
                if(upgradeProducts && upgradeProducts.length){
                    const upgradeProduct = upgradeProducts[0];

                    const upgradePlans = upgradeProduct.plans.filter(plan => {
                        return (
                                plan.periodicity_type === user.team.subscription.plan.periodicity_type &&
                                plan.id != user.team.subscription.plan.id && 
                                plan.price > user.team.subscription.plan.price
                        );
                    });
                    console.log("UPGRADE PLANS", upgradePlans);
                    setUpgradeEnterprisePlans(upgradePlans);
                } 
            })
            .catch(e => {
                console.debug("error getting products", e, {campaign: 'enterprise-plus'});
            });
        }
    }, [selectedTab]);
    
    //Custom Handler/Methods/Functions

    const handleChangeCurrentPassword = () => {

    };
    const handleChangeRepeatPassword = (val) => {
        setRepeatPassword(val);
    };
    const handleChangeNewPassword = (val) => {
        setNewPassword(val);
    };
    const handleNewPasswordSave = async () => {
        //console.log("handleNewPasswordSave",newPassword, repeatPassword);
        let passwordSaveError=false;
        setPasswordError(null);
        if(!newPassword && !repeatPassword){
            setPasswordError("Passwords cant't be empty");
            return;
        }
        if(newPassword !== repeatPassword){
            setPasswordError("Passwords don't match", newPassword, repeatPassword);
            
            return;
        }

        setLoadingGeneral(true);
        
        let data = {
            password: newPassword,
        };
        try{
            await userFn(data);
        } catch(error){ 
            //console.log("error", error.errors.password);
            if(error.errors && error.errors && error.errors.password && error.errors.password.length){
                setPasswordError(error.errors.password);
            } else{
                setPasswordError(error.message);
            }

            passwordSaveError=true;
        }

        setNewPassword(null);
        newPasswordRef.current.setValue('');
        setRepeatPassword(null);
        repeatPasswordRef.current.setValue('');
        
        setLoadingGeneral(false);
        console.log("Save password result", passwordSaveError);
        setPasswordChangeSuccessModal(!passwordSaveError);
    };

    const handlePersonalData = (val) => {
        setPersonalData(prev => {
            let newData = {...prev, ...val};
            return newData;
        });
    };

    const handleSavePersonalData = async () => {

        setLoadingGeneral(true);
        
        let data = {};
        Object.keys(personalData).forEach(key => {
            
            if(['profession','software_preferences', 'usage_purpose', 'website_channel', 'how_hear', 'next_desire'].includes(key)){
                data[key] = personalData[key];
            }
            
        });
        try{
            await meFn(data);
        }catch(e){
            console.log("ERROR SAVING PERSONAL DATA", e);
        }
        setLoadingGeneral(false);
    };

    const handleBillingChange = (val) => {
        setBillingData(prev => {
            let newData = {...prev, ...val};
            return newData;
        });
    };
    const handleSaveBillingData = async () => {

        setLoadingGeneral(true);
        
        let data = {};
        Object.keys(billingData).forEach(key => {
            
            if(['business_name', 'address', 'vat', 'invoice_notes'].includes(key)){
                data[key] = billingData[key];
            }
            
        });
        await teamFn.settings(data);
        setLoadingGeneral(false);
    };
    

    const handleSubscribeNewsletter = async () => {
        setLoadingGeneral(true);
        try{
            await meFn({newsletter:true});
        }catch(e){
            console.log("ERROR SUBSCRIBING NEWSLETTER", e);
        }
        setNewsletter(true);
        setLoadingGeneral(false);
    }
    const handleUnsubscribeNewsletter = async () => {
        setLoadingGeneral(true);
        try{
            await meFn({newsletter:false});
        }catch(e){
            console.log("ERROR UNSUBSCRIBING NEWSLETTER", e);
        }
        setNewsletter(false);
        setLoadingGeneral(false);
    };

    const handleEnableFootagecrateBlender = async () => {
        setLoadingGeneral(true);
        try{
            await meFn({footagecrateBlenderAddon:true});
        }catch(e){
            console.log("ERROR ACTIVATING FOOTAGE BLENDER WEB LINK", e);
        }
        setFootagecrateBlender(true);
        setLoadingGeneral(false);
    }
    const handleDisableFootagecrateBlender = async () => {
        setLoadingGeneral(true);
        try{
            await meFn({footagecrateBlenderAddon:false});
        }catch(e){
            console.log("ERROR DISABLING FOOTAGE BLENDER WEB LINK", e);
        }
        setFootagecrateBlender(false);
        setLoadingGeneral(false);
    };

    const handleRequestAutomaticInvoice = async () => {
        setLoadingGeneral(true);
        try{
            await meFn({automatic_invoice:true});
        }catch(e){
            console.log("ERROR REQUESTING AUTOMATIC INVOICE", e);
        }
        setAutomaticInvoice(true);
        setLoadingGeneral(false);
    };
    const handleStopAutomaticInvoice = async () => {
        setLoadingGeneral(true);
        try{
            await meFn({automatic_invoice:false});
        }catch(e){
            console.log("ERROR STOPPING AUTOMATIC INVOICE", e);
        }
        setAutomaticInvoice(false);
        setLoadingGeneral(false);
    };

    const getAvailableDownloads = () => {
        getAssetsAvailability().then(data => {
            //console.log("assets available", data);
            let remaining = 0;
            data.forEach(d => {
                if (d.name === 'assets-download') {
                    remaining = d.remaining;
                }
            });
            setAssetsAvailable(remaining);
            if(headerRef.current && headerRef.current.assignAssetsAvailable){
                headerRef.current.assignAssetsAvailable(remaining);
            }
        });
    };


    const handleUpgradeClick = option => {
        let provider = null;
        switch(option){
            case 'register':
                navigate('/register', { replace: true });
            break;
            case 'paypal':
                provider = 'paypal'
            break;
            case 'stripe':
                provider = 'stripe'
            break;
        }

        
        if(defaultProducts && defaultProducts.length){
            let selectedPlan = defaultProducts[1].plans.find(e => e.periodicity_type==='Month' && e.price>0);
            let postData = {provider, planId: selectedPlan.id};
            subscribeUserFn(postData).then(data => {
                if(data.url){
                    document.location.href = data.url;
                }
            })
            .catch(e => {
                console.log("ERROR SUBSCRIBING", e);
            });
        }
        
    }

    const handleCancelSubscription = () => {
        
        setLoadingGeneral(true);
        setCancelConfirmModal(false);
        cancelSubscription().then(async data => {
            console.log("Subscription cancelled", data);
            setTimeout(async () => {
                await reloadCurrentUser();
                setLoadingGeneral(false);
                setCancelSuccessModal(true);
            }, 1500);
            
        })
        .catch(e => {
            console.log("cancel subscription problem", e);
        });
    };

    const handleDeleteMe = () => {
        if(!window.confirm(`Are you sure you want to delete your user and all of it's data from productioncrate databases? This can not be undone.`)){
            return;
        }
        setLoadingGeneral(true);
        deleteUserFn().then(async data => {
            console.log("User deleted", data);
            setLoadingGeneral(false);
            navigate("/register");
        });
    };

    const fetchTeamAndInvites = (memberCallback, invitationCallback) => {
        setTeamMembersLoading(true);
        teamFn.members.list().then(members => {
            console.log("TEAM", members);
            setTeamMembers(members);
            if(memberCallback){
                memberCallback();
            }
            setTeamMembersLoading(false);
        });
        if(!user.is_team_owner){
            return;
        }
        setTeamInvitesLoading(true);
        teamFn.invitations.list().then(invites => {
            console.log("TEAM INVITATIONS", invites);
            setTeamInvites(invites);
            if(invitationCallback){
                invitationCallback();
            }
            setTeamInvitesLoading(false);
        });
    };
    const handleMemberDelete = (index, id) => {
        setTeamMembers(prev => {
            let newMembers = [...prev];
            newMembers[index].isDeleting = true;
            return newMembers;
        });
        teamFn.members.delete(id).then(data => {
            console.log("MEMBER DELETE", data);
            fetchTeamAndInvites();
        });
    };

    const handleInviteDelete = (id) => {
        setTeamInvites(currentInvites => currentInvites.filter(invite => invite.id !== id));
        teamFn.invitations.delete(id).then(data => {
            console.log("INVITE DELETE", data);
            fetchTeamAndInvites();
        });
    };
    const handleInviteEmail = () => {
        setInviteError(null);
        setInviteSuccess(null);
        setSendingInvite(true);

        if(!teamInvitesEmail){
            setInviteError("Email field can't be empty");
            return;
        }

        teamFn.invitations.invite(teamInvitesEmail).then(data => {
            console.log("INVITE", data);
            fetchTeamAndInvites(null, () => {setSendingInvite(false);setInviteSuccess(data.message);});
        }).catch(error => {
            console.log("ERROR INVITE", error);
            setSendingInvite(false);
            setInviteError(error.message);
            
        });
        
    };

    const upgradeEnterprise = (newPlan) => {
        if(!plansRef.current){
            return;
        }
        plansRef.current.setUpgradeSettingsValue(user.team.subscription.plan.price, newPlan.price);
        plansRef.current.setConfirmUpgradeValue(newPlan.id);
    };

    const questionnaireCallback = () => {

        if (user.team.subscription.plan.periodicity_type.toLowerCase() === 'month' && +user.team.subscription.plan.price === 49 && user.team.subscription.stripe) {
            setApplyPromoModal(true);
            return;
        }
        setCancelConfirmModal(true);
    }

    const applyPromoSubscribe = () => {
        setApplyPromoModal(false);
        if(!plansRef.current || !defaultProducts){
            return;
        }
        let proProduct = defaultProducts.filter(pr => pr.name.toLowerCase() === 'pro');
        if(!proProduct || !proProduct.length){
            return;
        }
        let discountedPlan = proProduct[0].plans.find(e => e.periodicity_type==='Month' && e.price===39);
        if(!discountedPlan){
            return;
        }

        plansRef.current.doUpgradeSubscription(discountedPlan.id);
    }
    return (
        <div className="layout">
            <Header ref={headerRef} />
            {!user &&
                <div className="empty-content">
                    <Spinner />
                </div>
            }
            {user && 
            <div className="member m-h-a">
                <Modal
                    visible={cancelSuccessModal}
                    title="Subscription Cancelled!"
                    cta="Close"
                    ctaCallback={() => setCancelSuccessModal(false)}
                    style={{height: '200px'}}
                />
                <Modal
                    visible={passwordChangeSuccessModal}
                    title="Your password has been updated successfully!"
                    cta="Close"
                    ctaCallback={() => setPasswordChangeSuccessModal(false)}
                    style={{height: '200px'}}
                />
                <div className="user">
                    <div className="pic m-b-16">
                        {false && <span className="glyphicon glyphicon-user" style={{color: '#fff'}}></span>}
                        <img src={`https://www.gravatar.com/avatar/${md5((user?user.email:'').trim().toLowerCase())}?s=200&d=${encodeURIComponent('https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg')}`} />
                    </div>
                    <div className="name m-b-16">{user.username} {(user.first_name || user.last_name) && user.first_name!=='no_firstname'?`( ${user.first_name} ${user.last_name} )`:''}</div>
                    {false &&
                    <div className="warning">
                    This is a notification that our browser extension 'Crate's Connect' is causing some users to have issues downloading our content. We are aware of the issue and are working to resolve it. In the meantime, please disable the extension to bypass this issue.
                    </div>
                    }
                    <Membership cta={() => setSelectedTab('subscriptions')} />
                    <div className="subtitle">You can now access thousands of HD production goodies to amp up your videos</div>
                </div>
                <div className="tab-list">
                    <div className={`tab ${selectedTab=='settings'?'active':''}`} onClick={() => setSelectedTab('settings')}>Settings</div>
                    <div className={`tab ${selectedTab=='subscriptions'?'active':''}`} onClick={() => setSelectedTab('subscriptions')}>Subscription</div>
                    <div className={`tab ${selectedTab=='favorites'?'active':''}`} onClick={() => setSelectedTab('favorites')}>
                        Assets Download History
                    </div>
                    {user.isEnterprise && 
                    <div className={`tab ${selectedTab=='team'?'active':''}`} onClick={() => setSelectedTab('team')}>
                        My Team
                    </div>
                    }
                </div>
                {loadingGeneral && 
                    <div className="general-loading">
                        <Spinner label="Please wait..." />
                    </div>
                }
                {selectedTab==='favorites' && 
                <>
                    {false && <Favorites />}
                    <Consumptions />
                </>
                }
                {selectedTab==='settings' && 
                <>
                    <FormatSelection settings={user.settings} settingsCallback={reloadCurrentUser} />
                    <div className="additional-data row-section">
                        <div className="additional-data-form">
                        <Input  
                            label="Profession:"
                            options={[
                                { label: '', value: null },
                                { label: 'Vlogger', value: 'Vlogger' },
                                { label: 'Business owner', value: 'Business owner' },
                                { label: 'VFX artist', value: 'VFX artist' },
                                { label: 'Motion graphics artist', value: 'Motion graphics artist' },
                                { label: 'Teacher', value: 'Teacher' },
                                { label: 'Student', value: 'Student' },
                                { label: 'Video editor', value: 'Video editor' },
                                { label: 'Filmmaker', value: 'Filmmaker' },
                                { label: 'Developer', value: 'Developer' },
                                { label: 'Other', value: 'other' },
                            ]}
                            onChange={(value) => handlePersonalData({'profession': value})}
                            ref={personalDataRef['profession']}
                        />
                        <Input  
                            label="Software Preferences:"
                            options={[
                                { label: '', value: null },
                                { label: 'Not Sure', value: 'Not Sure' },
                                { label: 'After Effects', value: 'After Effects' },
                                { label: 'Hitfilm', value: 'Hitfilm' },
                                { label: 'iMovie', value: 'iMovie' },
                                { label: 'Adobe Premiere', value: 'Adobe Premiere' },
                                { label: 'DaVinci Resolve', value: 'DaVinci Resolve' },
                                { label: 'Final Cut', value: 'Final Cut' },
                                { label: 'Wondershare filmora', value: 'Wondershare filmora' },
                                { label: 'Fusion', value: 'Fusion' },
                                { label: 'Vegas', value: 'Vegas' },
                                { label: 'Kinemaster', value: 'Kinemaster' },
                                { label: 'Nuke', value: 'Nuke' },
                                { label: 'Shotcut', value: 'Shotcut' },
                                { label: 'Blender', value: 'Blender' },
                                { label: 'Power Director', value: 'Power Director' },
                                { label: 'Lightworks', value: 'Lightworks' },
                                { label: 'Movavi', value: 'Movavi' },
                                { label: 'Photoshop', value: 'Photoshop' },
                                { label: 'No Preference', value: 'No Preference' },
                                { label: 'Other', value: 'other' },
                            ]}
                            onChange={(value) => handlePersonalData({'software_preferences': value})}
                            ref={personalDataRef['software_preferences']}
                        />
                        <Input  
                            label="Usage Purpose:"
                            options={[
                                { label: '', value: null },
                                { label: 'Not Sure', value: 'Not Sure' },
                                { label: 'Class Project', value: 'Class Project' },
                                { label: 'Feature Film', value: 'Feature Film' },
                                { label: 'Client Projects / Commercials', value: 'Client Projects / Commercials' },
                                { label: 'YouTube videos / Youtube promotion', value: 'YouTube videos / Youtube promotion' },
                                { label: 'Just Browsing', value: 'Just Browsing' },
                                { label: 'Other', value: 'other' },
                              ]}
                              onChange={(value) => handlePersonalData({'usage_purpose': value})}
                              ref={personalDataRef['prusage_purposeofession']}
                        />
                        <Input  
                            label="Website / Channel:"
                            input={true}
                            onChange={(value) => handlePersonalData({'website_channel': value})}
                            ref={personalDataRef['website_channel']}
                        />
                        <Input  
                            label="How did you hear about us?"
                            options={[
                                { label: '', value: null },
                                { label: 'Youtube', value: 'Youtube' },
                                { label: 'Google Search', value: 'Google Search' },
                                { label: 'Feature Film', value: 'Feature Film' },
                                { label: 'Friend', value: 'Friend' },
                                { label: 'Advertisement', value: 'Advertisement' },
                                { label: 'Other', value: 'other' },
                              ]
                              }
                            onChange={(value) => handlePersonalData({'how_hear': value})}
                            ref={personalDataRef['how_hear']}
                        />
                        <Input  
                            label="What do you want us to make next?:"
                            input={true}
                            onChange={(value) => handlePersonalData({'next_desire': value})}
                            ref={personalDataRef['next_desire']}
                        />
                        <div className="submit-form" onClick={handleSavePersonalData}>Submit</div>
                        </div>
                        
                    </div>
                    <div className="change-password row-section">
                        <div className="title m-b-8">
                            <span className="glyphicon glyphicon-info-alt" ></span>Change password
                        </div>
                        <form onSubmit={event => {event.preventDefault();handleNewPasswordSave();}} >
                        <div className="additional-data-form">
                            <input type="submit" style={{display: "none"}} />
                            <Input  
                                label="New Password"
                                input={true}
                                onChange={(value) => handleChangeNewPassword(value)}
                                type={"password"}
                                ref={newPasswordRef}
                            />
                            <Input  
                                label="Repeat:"
                                input={true}
                                onChange={(value) => handleChangeRepeatPassword(value)}
                                type={"password"}
                                ref={repeatPasswordRef}
                            />
                            {passwordError && Array.isArray(passwordError) &&
                                <div className="error">
                                    {passwordError.map((error, k) => <div key={`error-key-${k}`}>{error}</div>)}
                                </div>
                            }
                            {passwordError && !Array.isArray(passwordError) &&
                                <div className="error">
                                    {passwordError}
                                </div>
                            }
                            <div className="submit-form" onClick={()=>handleNewPasswordSave()}>Save</div>
                        </div>
                        </form>
                    </div>
                    <div className="help-support row-section">
                        <div className="title m-b-8">
                            <span className="glyphicon glyphicon-info-alt" ></span>Help & Support
                        </div>
                        Message us with any questions regarding the sites or resources, and we'll get back to you within 24 hours.
                        <div className="btn-row">
                            <a className="btn" href="https://www.productioncrate.com/support/index.html">
                                <span className="glyphicon glyphicon-info" ></span>
                                Knowledge Base
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                            <a className="btn" href="https://www.productioncrate.com/questions-answers.html">
                                <span className="glyphicon glyphicon-question"></span>
                                FAQ
                                <span className="glyphicon glyphicon-right"></span>    
                            </a>
                            <a className="btn" href="mailto:support@productioncrate.com">
                                <span className="glyphicon glyphicon-envelope"></span>
                                Email Support
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                        </div>
                    </div>
                    <div className="news-updates row-section">
                        <div className="title m-b-8">
                            <span className="glyphicon glyphicon-bell" ></span>News & Updates:
                        </div>
                        <div className="claim">
                            <a href="https://vfx.productioncrate.com/search.html#query=new">Bookmark us</a> & <a href="https://www.youtube.com/channel/UCtl5VdRFeerk0-xAGBpMbdw?sub_confirmation=1" target="_blank">Subscribe</a> to find out about new ProductionCrate content first!
                        </div>
                        <ul className='feat-list'>
                            <li>
                                <a href="https://vfx.productioncrate.com/search.html#query=new">
                                View the latest 100 resources anytime by searching "new" (new items are bulk uploaded every few days).
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/productioncrate">
                                Catch our demos and promos by subscribing on YouTube (hit the bell for updates)
                                </a>
                            </li>
                            <li>
                                For more tips, teasers, and behind the scenes stuff - follow us on facebook, twitter, and instagram. Here is our facebook group page where you can post your video productions for feedback from us and the community.
                            </li>
                        </ul>
                        <div className="btn-row">
                            {!newsletter && 
                            <a className="btn" onClick={handleSubscribeNewsletter}>
                                Subscribe to email newsletter
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                            }
                            {newsletter && 
                            <a className="btn btn-danger" onClick={handleUnsubscribeNewsletter}>
                                Unsubscribe from email newsletter
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                            }
                            <a className="btn" href="https://www.instagram.com/productioncrate">
                                Follow on Instagram
                                <span className="glyphicon glyphicon-right"></span>    
                            </a>
                            <a className="btn" href="https://www.youtube.com/user/FootageCrate?sub_confirmation=1">
                                Subscribe on YouTube
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                            <a className="btn" href="https://vfx.productioncrate.com/search.html#query=new">
                                Latest 500 Effects
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                            <a className="btn" href="https://www.productioncrate.com/content-queue.html">
                                User Requests
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                        </div>
                    </div>
                    <div className="referral-program row-section">
                        <div className="title m-b-8">
                        <span className="money">$</span>Earn Revenue with ProductionCrate!
                        </div>
                        <ul className='feat-list'>
                            <li>
                            Get paid 15% commission from all sales referrals, including future recurring sales!
                            </li>
                            <li>
                            Payouts are processed automatically.
                            </li>
                            <li>
                            Links can be shared in credits, descriptions, or anywhere you see fit.
                            </li>
                        </ul>
                        <div className="btn-row">
                            <a className="btn" href="https://productioncrate.partnerstack.com/">
                                Join Affilliate Program
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                        </div>
                    </div>
                    <div className="referral-program row-section">
                        <div className="title m-b-8">
                        <span className="glyphicon glyphicon-info-alt" ></span>FootageCrate Blender Addon
                        </div>
                        
                        <div className="btn-row">
                            {!footagecrateBlender && 
                            <a className="btn" onClick={handleEnableFootagecrateBlender}>
                                Enable FootageCrate Blender web link 🔥
                            </a>
                            }
                            {footagecrateBlender && 
                            <a className="btn btn-danger" onClick={handleDisableFootagecrateBlender}>
                                Disable FootageCrate Blender web link
                                <span className="glyphicon glyphicon-right"></span>
                            </a>
                            }
                        </div>
                    </div>
                </>
                }
                {selectedTab==='subscriptions' && 
                    <>
                    <div className="user-level row-section">
                        {!premiumAssetsPolicy && 
                        <>
                            <Membership />
                            <div className="claim">You can download {planDownloadsPerDay} effects per day from the free archives</div>
                            <ul className="feat-list ">
                                <li>About a third of out content is free</li>
                                <li>Items marked with a star (<span className="glyphicon glyphicon-star star"></span>) are exclusively PRO</li>
                                <li>You have {assetsAvailable} downloads remaining today.</li>
                                {user.team.subscription && user.team.subscription.plan.price>1 &&
                                <li>
                                    {`Plan's settings: ${user.team.subscription?`$${user.team.subscription.plan.price} per ${user.team.subscription.plan.periodicity_type} since ${user.team.subscription.started_at.slice(0, 10)}`:'processing...'}`}
                                </li>
                                }
                            </ul>
                            <div className="upgrade">
                                <div className="label">Upgrade:</div>
                                {false && <div className="btn" onClick={() => handleUpgradeClick("paypal")}>Paypal</div>}
                                {false && <div className="btn" onClick={() => handleUpgradeClick("stripe")}>Credit / Debit Cards</div>}
                                <div className="btn" onClick={() => handleUpgradeClick("register")}>Credit / Debit Cards</div>
                                <div className="accent m-t-18">
                                    Upgrade for Full Access to our Best Quality Content.
                                </div>
                                <div className="note">
                                    Access to all our libraries, Downloads up to 4K, Thousands more items to choose from, 200+ daily downloads.
                                </div>
                            </div>
                        </>
                        }
                        {premiumAssetsPolicy && 
                        <>
                            <Membership />
                                
                            {['ENTERPRISE', 'EDUCATORS'].includes(user.team.subscription.plan.product.name) && 
                                <div className="claim">Your team can download {planDownloadsPerDay} assets per day.</div>
                            }
                            {!['ENTERPRISE', 'EDUCATORS'].includes(user.team.subscription.plan.product.name) && 
                                <div className="claim">You can download {planDownloadsPerDay} assets per day.</div>
                            }
                            
                            <ul className="feat-list ">
                                <li>You have total access to all the best quality items from the ProductionCrate archives.</li>
                                <li><a href="https://vfx.productioncrate.com/">Browse thousands of VFX & media elements from the FootageCrate archives.</a></li>
                                <li><a href="https://sfx.productioncrate.com/">Browse thousands SFX & music assets from the SoundsCrate archives.</a></li>
                                <li><a href="https://graphics.productioncrate.com/">Browse Ultra-HD production ready images and textures from the GraphicsCrate archives.</a></li>
                                <li><a href="https://www.productioncrate.com/3D/">Browse photorealistic objects from the RenderCrate archives.</a></li>
                                <li><a href="https://contests.productioncrate.com/">Compete in the monthly 10 second video contest to win prizes and glory.</a></li>
                                <li><a href="https://learn.productioncrate.com/tutorials.html">Improve your production quality by learning with our tutorials.</a></li>
                                <li>You have {assetsAvailable} downloads remaining today.</li>
                                {user.team.subscription && user.team.subscription.plan.price>1 &&
                                <li>{`Plan's settings: ${user.team.subscription?`$${user.team.subscription.plan.price} per ${user.team.subscription.plan.periodicity_type} since ${getReadableDate(user.team.subscription.started_at)}`:'processing...'}`} .
                                </li>
                                }
                                {user.isEnterprise && 
                                <li>You have {user.isEnterprise} PRO user accesses included in your membership.</li>
                                }
                            </ul>
                            <div className='claim'>Enjoy, and make it awesome!</div>
                        </>
                        }
                    </div>
                    
                    <div className="invoicing row-section">
                        <div className="title m-b-8">
                            <span className="glyphicon glyphicon-newspaper" ></span>Invoicing
                        </div>
                        <div className="invoicing-data-form">
                            <Input  
                                label="Billing name:"
                                input={true}
                                onChange={(value) => handleBillingChange({'business_name': value})}
                                ref={billingDataRef['business_name']}
                            />
                            <Input  
                                label="VAT number:"
                                input={true}
                                onChange={(value) => handleBillingChange({'vat': value})}
                                ref={billingDataRef['vat']}
                            />
                            <Input  
                                label="Billing Address:"
                                input={true}
                                onChange={(value) => handleBillingChange({'address': value})}
                                ref={billingDataRef['address']}
                            />
                            <Input  
                                label="Additional details:"
                                input={true}
                                onChange={(value) => handleBillingChange({'invoice_notes': value})}
                                ref={billingDataRef['invoice_notes']}
                            />
                            <div className="btn-row">
                                <a className="btn" onClick={handleSaveBillingData}>
                                    Save Billing Information
                                    <span className="glyphicon glyphicon-right"></span>
                                </a>
                                {false && !automaticInvoice &&
                                <a className="btn" onClick={handleRequestAutomaticInvoice}>
                                    Request Automatic Invoicing
                                </a>
                                }
                                {false && automaticInvoice &&
                                <a className="btn btn-danger" onClick={handleStopAutomaticInvoice}>
                                    Stop Automatic Invoicing
                                </a>
                                }
                            </div>
                        </div>
                        <div className='note m-t-12'>Download Past period invoices:</div>
                        <ul className="feat-list">
                            {invoices.map((invoice, index) =>
                                <li key={`invoice-${index}`}>
                                    <a href={invoice.url} target="_blank">
                                    Total: ${invoice.total} Issued on {invoice.issue_at} <span className="glyphicon glyphicon-cloud-download" ></span>
                                    </a>
                                </li>   
                            )}
                        </ul>
                    </div>
                    {user.team.subscription.plan.price > 0 && user.team.subscription.state === 'active' &&
                    <div className={`row-section subscription-block ${subscriptionExpand?"active":""}`}>
                        <div className="title m-b-8" onClick={() => setSubscriptionExpand(!subscriptionExpand)}>
                            <span className="glyphicon glyphicon-lock" ></span>Subscription Management
                            <span className="glyphicon glyphicon-right menu-expand-icon" ></span>
                        </div>
                        <div className="hidden-content">
                            <div className='note m-b-8'>
                                Note: these actions are irreversible, walk carefully.
                            </div>
                            <div className="btn-row">
                                <div className="btn btn-danger" onClick={() => setQuestionnaireModal(true)}>
                                    Cancel Subscription
                                </div>
                                {false &&
                                <div className="btn btn-danger" onClick={() => handleDeleteMe()}>
                                    Delete all my user data
                                </div>
                                }
                            </div>
                        </div>
                        <Questionnaire user={user} visible={questionnaireModal} ctaCallback={() => setCancelConfirmModal(true)} expensiveCallback={questionnaireCallback} ctaCloseCallback={() => setQuestionnaireModal(false)} />
                        <Modal 
                            visible ={cancelConfirmModal}
                            title="Subscription Cancelation"
                            msg={
                                <div style={{fontSize:'16px', lineHeight: '26px'}}>
                                    {`Are you sure you want to cancel this subscription?`}
                                    <br />
                                    <b>
                                        {`${user.team.subscription.plan.product.name+' '+user.team.subscription.plan.name} ( $${user.team.subscription.plan.price} per ${user.team.subscription.plan.periodicity_type} )`}
                                    </b>
                                    <br />
                                    <div style={{color:'#ff4444'}}>
                                        {user.team.subscription.plan.cancellation_message?user.team.subscription.plan.cancellation_message:'You will lose any promotions previously applied, and this action cannot be reversed.'}
                                    </div>
                                </div>
                            }
                            className="danger"
                            showCloseIcon={true}
                            cta="Confirm"
                            ctaCallback={() => handleCancelSubscription()}
                            ctaClose="Back"
                            ctaCloseCallback={() => setCancelConfirmModal(false)}
                            overlayCallback={() => setCancelConfirmModal(false)}
                            style={{height: 'max-content'}}
                        />
                        <Modal 
                            visible ={applyPromoModal}
                            pretitle={`Account active since ${new Date(user.team.subscription.started_at).toLocaleDateString('en-US')}`}
                            title={<span style={{fontSize:'26px', lineHeight: '26px'}}><span style={{color: '#ff9f00'}}>{user.username !== ""?user.username:'You'}</span> {user.username !== ""?'qualifies':'qualify'} for a discount!</span>}
                            msg={
                                <span style={{fontSize:'22px', lineHeight: '28px'}}>
                                    <b>PRO</b> access at <span style={{textDecoration: 'line-through'}}>$49</span> <b>$39</b> per Month
                                </span>
                            }
                            showCloseIcon={false}
                            cta="Apply and Save 20%"
                            ctaCallback={applyPromoSubscribe}
                            ctaClose="Proceed with Cancelation"
                            ctaCloseCallback={() => handleCancelSubscription()}
                            overlayCallback={() => setApplyPromoModal(false)}
                            style={{height: 'max-content', borderWidth: '4px'}}
                            loadingSpinner={4000}
                            loadingLabel={"Checking account discount eligibility..."}
                        />
                    </div>
                    }
                    </>
                }
                {selectedTab==='team' && 
                <>
                    <div className="team row-section">
                        <div className="title m-b-8">
                            <span className="glyphicon glyphicon-user" ></span>Current members of your team.
                        </div>
                        <ul className="member-list ">
                        {teamMembers.length > 0 && teamMembers.map((item, index) => 
                            <li className="item m-b-8" key={`member-list-${index}`}>
                                <div className="m-r-4" >{item.username} ({item.email})</div>
                                {user.is_team_owner && !item.isDeleting && 
                                <span className="btn btn-secondary glyphicon glyphicon-cross" onClick={() => handleMemberDelete(index, item.id)}></span>
                                }
                                {user.is_team_owner && item.isDeleting && 
                                <span className="btn btn-secondary" >Removing...</span>
                                }
                            </li>
                        )}
                        {teamMembers.length === 0 && 
                            <li className="item">{teamMembersLoading?'Loading...':'No members in your team.'}</li>
                        }
                        </ul>
                        {user && user.is_team_owner && 
                        <div className="title m-b-8">
                            <span className="glyphicon glyphicon-hour-glass" ></span>Pending Invitations
                        </div>
                        }
                        <ul className="member-list ">
                        {user && user.is_team_owner && teamInvites.length > 0 && teamInvites.map((item, index) => 
                            <li className="item m-b-8" key={`member-list-${index}`}>
                                <div className="m-r-4" >{item.email}</div>
                                <span className="btn btn-secondary glyphicon glyphicon-cross" onClick={() => handleInviteDelete(item.id)}></span>
                            </li>
                        )}
                        {user && user.is_team_owner && teamInvites.length === 0 && 
                            <li className="item">{teamInvitesLoading?'Loading...':'No pending invitations.'}</li>
                        }
                        </ul>
                        {user && user.is_team_owner && 
                        <div style={{maxWidth: '500px'}}>
                            {!!teamMembers.length < user.isEnterprise &&
                            <>
                                <div className="title m-b-8">
                                    <span className="glyphicon glyphicon-envelope" ></span>Send invitation for your team
                                </div>
                                
                                <form onSubmit={event => {event.preventDefault();handleInviteEmail();}} >
                                    <div className="additional-data-form">
                                        <input type="submit" style={{display: "none"}} />
                                        <Input  
                                            label="Email Address"
                                            input={true}
                                            onChange={(value) => setTeamInvitesEmail(value)}
                                        />
                                        <div className="submit-form" onClick={() => sendingInvite || handleInviteEmail()}>Send invitation</div>
                                        {inviteError && Array.isArray(inviteError) &&
                                            <div className="error">
                                                {inviteError.map((error, k) => <div key={`error-key-${k}`}>{error}</div>)}
                                            </div>
                                        }
                                        {inviteError && !Array.isArray(inviteError) &&
                                            <div className="error">
                                                {inviteError}
                                            </div>
                                        }
                                        {inviteSuccess && !Array.isArray(inviteSuccess) &&
                                            <div className="success">
                                                {inviteSuccess}
                                            </div>
                                        }
                                        {sendingInvite && 
                                            <div className="normal">
                                                Sending invitation ...
                                            </div>
                                        }
                                    </div>
                                </form>
                            </>
                            }
                            {!!teamMembers.length >= user.isEnterprise &&
                            <div className="title m-b-24">
                                :( ... No free member slots in your team.
                            </div>
                            }
                            {!!upgradeEnterprisePlans.length && user.team.subscription.plan && 
                                <div className="m-t-24" style={{display: 'flex', gap: '12px', alignItems: 'center'}}>
                                    {upgradeEnterprisePlans.map((plan, index) =>
                                        <div className="btn" key={`upgrade-plans-${index}`} onClick={() => upgradeEnterprise(plan)}>Get up to {plan.name.replace(/\D/g, '')} PRO members</div>
                                    )}
                                </div>
                            }
                        </div>
                        }
                    </div>
                </>
                }
            </div>
            }
            <Footer />
            <Plans standalone={true} ref={plansRef} />
        </div>
    );
};

export default Member;

