import React, {useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resendVerificationEmail } from '../../lib/api';

import { useUserContext } from "../../contexts";
import '../../styles/layout.scss';
import './EmailVerify.scss';
import { Header } from '../Header';
import { Spinner } from '../Spinner';
import {ButtonCTA} from '../Form';


const EmailVerify = ({verify=false}) => {

    //Custom Hooks
    const navigate = useNavigate();
    const {user, isAuthenticating} = useUserContext();
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState(null);

    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    
    //UseEffect Hooks
    useEffect(() => {
        //console.log("SETUSER", user);
        if(!verify && !user && !isAuthenticating){
            console.log("No user in email verify page", user, isAuthenticating);
            navigate(`/login/`, { replace: true });
            return;
        }
        if (searchParams) {
            setToken(searchParams.get('token'));
        }
    }, [user]);



    const handleResendEmail = async () => {
        setErrorMsg(false);
        setSuccessMsg(false);
        
        try{
            const res = await resendVerificationEmail();
            //console.log("verification email resend");
            setSuccessMsg("Verification Email Sent!");
            setErrorMsg(false);
        } catch (err) {
            console.log("Verification email failed", err.error?err.error:err);
            setErrorMsg("Verification Email failed");
            setSuccessMsg(false);
        }    

    };

    return (
        <div className="layout">
            <Header search={() => null} />
            <div className="email-verify" >
                {user && !user.email_verified &&
                <div className="form" >
                    <div className="title m-b-16">Re-send Verification Email</div>
                    <div className="explanation big m-b-12">Your Email: {user.email}</div>
                    <div className="explanation m-b-12">Can't find your verification email? Click below to resend it.</div>
                    {errorMsg && 
                        <div className={`explanation error-msg m-b-12`}>{errorMsg}</div>
                    }
                    {successMsg && 
                        <div className={`explanation success-msg m-b-12`}>{successMsg}</div>
                    }
                    <ButtonCTA label="Re-send Email Verification" labelLoading="Sending..." onClick={handleResendEmail} />
                </div>
                }
                {verify &&
                <div className="form" >
                    <div className="title m-b-16">Thanks for verifying your email!</div>
                    {token && 
                        <ButtonCTA label="Start now" onClick={()=> document.location.href=`/login/email/callback?token=${token}`} />
                    }
                    {!token &&
                        <ButtonCTA label="Continue to Login" onClick={()=> document.location.href='/login'} />
                    }
                </div>
                }
                {!user && !verify &&
                    <Spinner />
                }

            </div>
        </div>
    );
};

export default EmailVerify;

