import React, {useState, useEffect, useRef} from 'react';
import { Spinner } from '../Spinner';
import './Modal.scss';


const Modal = ({title, pretitle, img, msg, className, cta, ctaCallback, ctaClose, ctaCloseCallback, ctaSize, ctaDisabled, overlayCallback, visible, style={}, showCloseIcon, loadingSpinner, loadingLabel}) => {
    //dummy padding

    const [showLoading, setShowLoading] = useState(!!loadingSpinner);

    if(visible && loadingSpinner){
        console.log("THIS MODAL IS VISIBLE", title, loadingSpinner);
        console.log("SPINNER",loadingSpinner, showLoading);
        setTimeout(() => setShowLoading(false), loadingSpinner);
    }
    const handleCtaClick = () => {
        if (ctaCallback) {
            ctaCallback();
        }
    };

    const handleCtaCloseClick = () => {
        if (ctaCloseCallback) {
            ctaCloseCallback();
        }
    };

    const handleOverlayClick = (event) => {
        event.preventDefault();
        if(overlayCallback){
            overlayCallback();
        }
        //console.log("overlay click");
    };
    return (
        <div className={`modal_container` } >
            <div className={`overlay ${visible?'visible':''}`} onClick={(event) => handleOverlayClick(event)}></div>
            <div className={`modal ${visible?'visible':''}  ${className?className:''}`} style={{...style, ['minHeight']: showLoading?'240px':'unset'}}>
                {showLoading && 
                    <Spinner label={<span style={{color: '#fff', fontSize: '18px', fontWeight: '600'}}>{loadingLabel??'Loading...'}</span>} />
                }
                {!showLoading && 
                <>
                    {showCloseIcon && <span className="glyphicon glyphicon-plus close" onClick={handleCtaCloseClick} ></span>}
                    {pretitle && <div className="modal_pretitle m-b-8">{pretitle}</div>}
                    {title && <div className="modal_title">{title}</div>}
                    {img && <img className="plan_img m-t-a m-b-a" src={img} width="120" height="120"></img>}
                    {msg && <div className="msg m-b-32 m-t-16">{msg}</div>}
                    {cta && <div className={`cta ${ctaSize?ctaSize:''} ${ctaDisabled?'disabled':''} m-t-36`} onClick={handleCtaClick}>{cta}</div>}
                    {ctaClose && <div className="cta-close m-t-32" onClick={handleCtaCloseClick}>{ctaClose}</div>}
                </>
                }
            </div>
        </div>
    );
};

export default Modal;

