import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUserContext } from "../../contexts";
import { subscribeUserFn, Analytics, getProducts } from '../../lib/api';
import { Signup } from './';
import { Spinner } from '../Spinner';
import { getCookie } from '../../lib/utils';

import './OnlySignup.scss';

const OnlySignup = () => {

  //Custom Hooks
  const {user, isAuthenticating} = useUserContext();

  const [searchParams] = useSearchParams();
  let { planId, provider} = useParams();

  //State to control the loading spinner
  const [showLoading, setShowLoading] = useState(false);
 

  const signupSuccessCallback = () => {
    setShowLoading(true);
    if(planId){
        console.log("Plan selected, subscription will happen");
    }else{
        console.log("No plan selected, nothing to do");
    }
  }
  

  const subscribeUser = async () => {
    let products = [];
    let plan = null;
    let campaign = searchParams.get("c")??false;
    try{
      products = await getProducts({campaign});
    }catch(e){
      console.log("Problem while getting products", e);
    }

    products.forEach(prod => {
        if(plan!==null){
            return;
        }
        prod.plans.forEach(p => {
            console.log(p.id, planId);
            if(+p.id === +planId){
                plan = p;
                return;
            }
        });
    });

    if (user && user.team && plan) {
        Analytics.signup(plan.price, {
            name: `${user.first_name || ''} ${user.last_name || ''} (${user.username || ''})`,
            email: user.email,
            team_id: user.team.id
        });
    }else{
        console.log("No user or plan found, nothing to do", user, planId, plan);
        return;
    }

    
    let postData = {provider:provider==='s'?'stripe':'paypal', planId};

    if(plan && plan.price === 0){
        //free registration, nothing to do but get redirected to member area
        Analytics.goalPlan(user.team.subscription.plan);

        setTimeout(() => document.location.href='/member', 1000);
        return;
    }
    //console.log("State before subscribing: (doantor, provider, planId)", donatorCheck, provider, selectedPlan);
    subscribeUserFn(postData).then(data => {
      if(data.url && data.type !=='success'){
        document.location.href = data.url;
      } else if(data.type === 'success' && data.url) {
          document.location.href = data.url;
      }else if(data.type ==='success') {
          setShowLoading(false);
      }
    })
    .catch(e => {
        console.log("Problem while subscribing user", e);
        setShowLoading(false);
    });

  };


  const closeCallback = () => {
    //a react function for history back
    window.history.back();
  };


  useEffect(() => {  
    if(!isAuthenticating && !user){
        console.log("HIDE LOADING");
        console.log("is not authenticating: show loading off");
        setShowLoading(false);
    }else if(isAuthenticating){
        console.log("SHOW LOADING");
        setShowLoading(true);
    }
  }, [isAuthenticating]);

  useEffect(() => {
    if(user && planId){
      console.log("susbcribe user: show loading on");
      setShowLoading(true);
      subscribeUser();
    }
  }, [planId, user]);

  useEffect(() => {
    console.log("PLan to subscribe to", planId);
  }, [planId]);

  return (
    <>
      {(showLoading || isAuthenticating) && 
      <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: '100vh'}}>
          <Spinner style={{position: 'relative'}} />
      </div>
      }
      {!showLoading && !user && 
      <Signup signupSuccessCallback={signupSuccessCallback} closeCallback={closeCallback} notClosable={true} />
      }
    </>
  );
};

export default OnlySignup;
