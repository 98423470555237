export default [
    {
        name: "Sample",
        img: "https://cdn.staticcrate.com/stock-hd/graphics/print-design/starter-plan3-0d1_prev_sm.2.png",
        cta: "Get Started",
        upgrade: "Upgrade Now",
        order: 2,
        features: [
            {
                variable: true,
                name: "Downloads Per Day",
                freeValue: 5,
                donatorValue: 20,
                tooltip: "Only Assets not Marked with a Star are Available with this Option.",
            },
            {
                name: "Limited VFX access",
                tooltip: "Access hundreds of free VFX from our basic libraries in standard resolution."
            },
            {
                name: "Limited Audio access",
                tooltip: "Access hundreds of sound effects and music from the SoundsCrate libraries.",
            },
            {
                name: "Limited Graphics access",
                tooltip: "Download free GraphicsCrate content in up to 2K resolution.",
            },
            {
                name: "Limited Models access",
                tooltip: "Access hundreds of free professional quality 3D models from the RenderCrate libraries.",
            },
            {
                name: "Limited Plugins access",
                tooltip: "Access our free powerful plugins, apps, AE extensions and more.",
            },
            {
                name: "HD 2K Downloads",
                tooltip: "Files from the basic libraries are limited to standard resolution.",
            }
        ],
    },
    {
        pretitle: "Save $10/mo by upgrading in your first week",
        name: "Pro",
        img: "https://cdn.staticcrate.com/stock-hd/graphics/print-design/proplan-illustration-01_prev_sm.2.png",
        accent: true,
        cta: "Get Started",
        upgrade: "Upgrade Now",
        order: 1,
        features: [
            {
                name: "50 Downloads Per Day",
                check: true,
                tooltip: "Download up to 50 items daily. Download counters resets daily at 12:00 AM PST.",
            },
            {
                name: "Full Access to VFX",
                check: true,
                tooltip: "Access ALL of the professional VFX and stock footage from the FootageCrate libraries."
            },
            {
                name: "Full Access to Audio",
                check: true,
                tooltip: "Access ALL of the professional sound effects and music from the SoundsCrate libraries.",
            },
            {
                name: "Full Access to Graphics",
                check: true,
                tooltip: "Access ALL of the professional Graphics, Illustrations and Textures from the GraphicsCrate libraries.",
            },
            {
                name: "Full Access to 3D Archives",
                check: true,
                tooltip: "Access ALL of the top quality Models, Materials, and HDRI's from the RenderCrate libraries.",
            },
            {
                name: "Full Access to Plugins",
                check: true,
                tooltip: "Access ALL plugins, scripts, apps, and extensions from the ProductionCrate software libraries.",
            },
            {
                name: "Full Access to LaForge",
                check: true,
                tooltip: "Access our next generation plugin library for Adobe After Effects and Premiere Pro.",
            },
            {
                name: "Highest Resolution",
                check: true,
                tooltip: "Download RAW HD source files including VFX and Models textured up to 6K resolution, Graphics in scalable vector formats and renders up to 12K resolution, professional 3D models, materials, audio compositions in WAV format and more.",
            },
            {
                name: "Best price guaranteed",
                check: true,
                tooltip: "Download up to 50 items daily. Download counters resets daily at 12:00 AM PST.",
            }
        ],
    },
    {
        name: "Enterprise",
        img: "https://cdn.staticcrate.com/stock-hd/graphics/print-design/enterprise-illus-01_prev_sm.2.png",
        cta: "Learn More",
        upgrade: "Upgrade Now",
        order: 3,
        features: [
            {
                name: "100 Downloads Per Day",
                check: true,
                tooltip: "Download up to 100 items daily. Download counters resets daily at 12:00 AM PST.",
            },
            {
                name: "Up to 5 team members",
                check: true,
                tooltip: "This plan includes an extended enterprise license and can be shared with teams of up to 5 people. For larger team requests and packages please contact support@productioncrate.com",
            },
            {
                name: "Full Access to VFX",
                check: true,
                tooltip: "Access ALL of the professional VFX and stock footage from the FootageCrate libraries."
            },
            {
                name: "Full Access to Audio",
                check: true,
                tooltip: "Access ALL of the professional sound effects and music from the SoundsCrate libraries.",
            },
            {
                name: "Full Access to Graphics",
                check: true,
                tooltip: "Access ALL of the professional Graphics, Illustrations and Textures from the GraphicsCrate libraries.",
            },
            {
                name: "Full Access to 3D Archives",
                check: true,
                tooltip: "Access ALL of the top quality Models, Materials, and HDRI's from the RenderCrate libraries.",
            },
            {
                name: "Full Access to Plugins",
                check: true,
                tooltip: "Access ALL plugins, scripts, apps, and extensions from the ProductionCrate software libraries.",
            },
            {
                name: "Full Access to LaForge",
                check: true,
                tooltip: "Access our next generation plugin library for Adobe After Effects and Premiere Pro.",
            },
            {
                name: "Highest Resolution",
                check: true,
                tooltip: "Download RAW HD source files including VFX and Models textured up to 6K resolution, Graphics in scalable vector formats and renders up to 12K resolution, professional 3D models, materials, audio compositions in WAV format and more.",
            },
            {
                name: "Server Storage Permissions",
                check: true,
                tooltip: "This plan includes an extended enterprise license which allows the source files to be stored on servers for easier team collaboration.",
            },
            {
                name: "Extended License",
                check: true,
                tooltip: false,
                link: 'https://www.productioncrate.com/enterprise-extended-license-agreement.html',
            }
        ],
    },
    {
        name: "Enterprise Plus 5",
        display_name: 'Team: 5 users',
        img: "https://cdn.staticcrate.com/stock-hd/graphics/print-design/enterprise-illus-01_prev_sm.2.png",
        cta: "Get Started",
        upgrade: "Upgrade Now",
        features: [
            {
                name: "Up to <b>5 team</b> members",
                check: true,
                tooltip: "This plan includes an extended enterprise license and can be shared with teams of up to 5 people.",
            },
            {
                name: "<b>100 Downloads</b> Per Day",
                check: true,
                tooltip: "Download up to 100 items daily per team. Download counters resets daily at 12:00 AM PST.",
            },
            {
                name: "Full Access to All Libraries",
                check: true,
                tooltip: "Access ALL professional assets including VFX, audio, graphics, 3D archives, and plugins from the FootageCrate, SoundsCrate, GraphicsCrate, RenderCrate, and ProductionCrate libraries."
            },
            {
                name: "Full Access to Plugins",
                check: true,
                tooltip: "Access ALL plugins, scripts, apps, and extensions from the ProductionCrate software libraries.",
            },
            {
                name: "Team management",
                tooltip: "Admin Access for Team Control to Add or Remove Users",
                check: true,
            },
            {
                name: "Extended License",
                check: true,
                tooltip: false,
                link: 'https://www.productioncrate.com/enterprise-extended-license-agreement.html',
            }
        ],
    },
    {
        name: "Enterprise Plus 10",
        display_name: 'Team: 10 users',
        img: "https://cdn.staticcrate.com/stock-hd/graphics/print-design/enterprise-illus-01_prev_sm.2.png",
        cta: "Get Started",
        upgrade: "Upgrade Now",
        features: [
            {
                name: "Up to <b>10 team</b> members",
                check: true,
                tooltip: "This plan includes an extended enterprise license and can be shared with teams of up to 10 people.",
            },
            {
                name: "<b>200 Downloads</b> Per Day",
                check: true,
                tooltip: "Download up to 200 items daily per team. Download counters resets daily at 12:00 AM PST.",
            },
            {
                name: "Full Access to All Libraries",
                check: true,
                tooltip: "Access ALL professional assets including VFX, audio, graphics, 3D archives, and plugins from the FootageCrate, SoundsCrate, GraphicsCrate, RenderCrate, and ProductionCrate libraries."
            },
            {
                name: "Full Access to Plugins",
                check: true,
                tooltip: "Access ALL plugins, scripts, apps, and extensions from the ProductionCrate software libraries.",
            },
            {
                name: "Team management",
                tooltip: "Admin Access for Team Control to Add or Remove Users",
                check: true,
            },
            {
                name: "Extended License",
                check: true,
                tooltip: false,
                link: 'https://www.productioncrate.com/enterprise-extended-license-agreement.html',
            }
        ],
    },
    {
        name: "Enterprise Plus 20",
        display_name: 'Team: 20 users',
        img: "https://cdn.staticcrate.com/stock-hd/graphics/print-design/enterprise-illus-01_prev_sm.2.png",
        cta: "Get Started",
        upgrade: "Upgrade Now",
        features: [
            {
                name: "Up to <b>20 team </b>members",
                check: true,
                tooltip: "This plan includes an extended enterprise license and can be shared with teams of up to 20 people. For larger team requests and packages please contact support@productioncrate.com",
            },
            {
                name: "<b>400 Downloads</b> Per Day",
                check: true,
                tooltip: "Download up to 400 items daily per team. Download counters resets daily at 12:00 AM PST.",
            },
            {
                name: "Full Access to All Libraries",
                check: true,
                tooltip: "Access ALL professional assets including VFX, audio, graphics, 3D archives, and plugins from the FootageCrate, SoundsCrate, GraphicsCrate, RenderCrate, and ProductionCrate libraries."
            },
            {
                name: "Full Access to Plugins",
                check: true,
                tooltip: "Access ALL plugins, scripts, apps, and extensions from the ProductionCrate software libraries.",
            },
            {
                name: "Team management",
                tooltip: "Admin Access for Team Control to Add or Remove Users",
                check: true,
            },
            {
                name: "Extended License",
                check: true,
                tooltip: false,
                link: 'https://www.productioncrate.com/enterprise-extended-license-agreement.html',
            }
        ],
    },
  ];