import { useEffect, useRef } from 'react';

function useClickable(onClickInside, onClickOutside) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current) {
        if (ref.current.contains(event.target)) {
          onClickInside();
        } else {
          onClickOutside();
        }
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onClickInside, onClickOutside]);

  return ref;
}

export default useClickable;