import React, {useEffect} from 'react';
import { useUserContext } from "../../contexts";
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../Spinner';
import '../../styles/layout.scss';

const Logout = () => {
    //Custom Hooks
    const {user, isAuthenticating, unloadUser} = useUserContext();
    const navigate = useNavigate();
    
    useEffect(() => {
        //Reset and redirect just in case something unexpected happens
        setTimeout(() => navigate('/login', { replace: true }), 5000);
    }, []);

    useEffect(() => {
        if(!isAuthenticating && !user){
            return;
        }
        if(isAuthenticating){
            return;
        }
        if(!user){
            navigate('/login', { replace: true });
            return;
        }
        unloadUser();
        setTimeout(() => navigate('/login', { replace: true }), 700);
    }, [user, isAuthenticating]);

    return (
        <div className="layout">
            <div className="empty-content">
                <Spinner />
            </div>
        </div>
    );
};

export default Logout;

