import Lottie from "lottie-react";
import productoncrateLogoAnimated from "./productioncrate.logo.animated.json";
import './Spinner.scss';


const Spinner = ({type='logo', loop=true, label="Loading...", style}) => {

    
    const preventClick = (event) => {
        event.stopPropagation();

    };
    
    return (
        <div className="spinner" onClick={preventClick} style={style}>
            {type == 'ripple' && 
            <div className="ripple"><div></div><div></div></div>
            }
            {type == 'logo' && 
            <Lottie animationData={productoncrateLogoAnimated} loop={loop} autoPlay style={{width: "80px"}}/>
            }
            {label && <div className="label">{label}</div>}
        </div>
    );
};

export default Spinner;
