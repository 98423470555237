import React, {useState, useEffect, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../lib/config';
import { useUserContext } from "../../contexts";
import { loginUserFn, providerLoginFn } from '../../lib/api'
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCookie, deleteCookie } from '../../lib/utils';
import { Header } from '../Header';
import { Spinner } from '../Spinner';
import { Modal } from '../Modal';
import '../../styles/layout.scss';
import './Login.scss';
import {ButtonCTA} from '../Form';

const Login = ({remoteCallback, standalone, closeCallback}) => {
    //Refs
    const formCTARef = useRef(null);
    const headerRef = useRef(null);
    const reCaptchaRef = useRef(null);

    //Custom Hooks
    const {user, isAuthenticating, setUserToken, setRefreshToken, setFocusMode} = useUserContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showRedirectToApp, setShowRedirectToApp] = useState(false);

    const [showFacebookWarning, setShowFacebookWarning] = useState(false);

    //UseEffect callbacks
    useEffect(() => {
        if(remoteCallback){
            setLoading(true);
            let tokenResponse = searchParams.get('token');
            
            if(tokenResponse){
                switch(remoteCallback){
                    case 'google':
                        setTimeout(() => oAuthSuccessCallback('google', { access_token: decodeURIComponent(tokenResponse) }), 1000);
                        break;
                    case 'facebook':
                        setTimeout(() => oAuthSuccessCallback('facebook', { access_token: decodeURIComponent(tokenResponse) }), 1000);
                        break;
                    case 'email':
                        setTimeout(() => successCallback(tokenResponse), 1000);
                        break;
                }
                return;
            }

            let refreshTokenResponse = searchParams.get('refresh_token');
            if(refreshTokenResponse){
                switch(remoteCallback){
                    case 'portal':
                        setFocusMode(true);
                        setShowLoginForm(false);
                        const portalUrl =`productioncrate-portal://google-auth?refresh_token=${refreshTokenResponse}`;
                        try{
                            document.location.href=portalUrl;
                        }catch(e){
                            console.log("error while redirecting to portal automatically.");
                        }
                        
                        setTimeout(() => {
                            setLoading(false);
                            setShowRedirectToApp(portalUrl);
                        }, 2000);
                        break;
                }
                
                return;
            }
            return;
        }
    }, []);

    useEffect(() => {
        if (!user) {
            setLoading(!!remoteCallback || isAuthenticating);
            return;
        }
        if (standalone) {
            return;
        }
    
        // Check for crateLoginRedirect cookie
        const crateLoginRedirect = getCookie("crateLoginRedirect");
        if (crateLoginRedirect) {
            // Updated regex to allow URLs with or without a URI path
            deleteCookie("crateLoginRedirect");
            if (typeof crateLoginRedirect === 'string' && /^(https:\/\/([\w-]+\.)*productioncrate\.com(\/.*)?)$|^\/[^\/]*$/.test(crateLoginRedirect)) {            
    
                // Use document.location.href for absolute URLs; navigate for relative paths
                if (crateLoginRedirect.startsWith('https://')) {
                    document.location.href = crateLoginRedirect;
                } else {
                    navigate(crateLoginRedirect, { replace: true });
                }
                return;
            }
        } else {
            // Check for redirect_uri and optional redirect_sub query parameters
            const redirectUri = searchParams.get('redirect_uri');
            const redirectSub = searchParams.get('redirect_sub') || '';
    
            if (redirectUri && redirectUri.startsWith('/')) {
                const redirectUrl = redirectSub 
                    ? `https://${redirectSub}.productioncrate.com${redirectUri}`
                    : `https://productioncrate.com${redirectUri}`;
    
                document.location.href = redirectUrl; // Use document.location.href for full redirection
                return;
            }
        }
    
        // Default navigation if no redirection path is found
        navigate('/member', { replace: true });
    }, [user, isAuthenticating]);

    //Handlers:
    const handleEmailLogin = async () => {
        /*
        let recaptchaToken;
        try{
            recaptchaToken = await reCaptchaRef.current.executeAsync();

            console.log("RECAPTCHA token", recaptchaToken);
        }catch(e){
            console.log("RECAPTCHA ERROR", e);
            return;
        }
        */
        let data = {
            "password": password,
            //'g-recaptcha-response': recaptchaToken,
        };
        

        if(username.includes('@')){
            data.email = username;
        } else {
            data.username = username;
        };  
        try {
            const {access_token, refresh_token} = await loginUserFn(data);
            successCallback(access_token, refresh_token);
        
        } catch (res) {
            //console.log("log in failed", res.error?res.error:res);
            if(res.error && res.error == "Unauthorized") {
                setError("User or password incorrect");
            }
        };
        
    };

    const oAuthSuccessCallback = (provider,payloadResponse) => {
        providerLoginFn(provider, payloadResponse)
        .then(({ access_token, refresh_token }) => {
            successCallback(access_token, refresh_token);
        })
        .catch(e => {
            console.log("oAuthSuccessCallback error", e);
        });
    };
    const googleSuccessCallback = (tokenResponse) => {
        oAuthSuccessCallback('google', tokenResponse);
    };

    const googleLogin = useGoogleLogin({
        onSuccess: googleSuccessCallback,
        onError: response => console.error(response.error_description ?? 'OAuth Error'),
    });

    const handleFacebookLogin = (response) => {
        //console.log(response);
        oAuthSuccessCallback('facebook', { access_token: response.accessToken, refresh_token: response.refreshToken });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        //console.log("Form Submit", formCTARef.current);
        formCTARef.current.click();
    }

    const successCallback = (token, refresh_token) => {
        setUserToken(token);
        setRefreshToken(refresh_token);
    };

    const handleFacebookWarning = () => {
        setShowFacebookWarning(true);
    };

    return (
        <div className="layout">
            {!standalone && 
                <Header ref={headerRef} />
            }
            <Modal 
                visible ={showFacebookWarning}
                title="Facebook is no longer supported!"
                msg={<div className="submsg">
                    Hello,<br />
                    ProductionCrate will no longer be offering the option to log in with Facebook, and are moving to a more secure system.<br />
                    You will need to use your email address and password to log in. To create your password, please click the following link: 
                    <a href='/password-recovery' alt='' style={{color:'#f68802', marginLeft:'4px'}}>password-recovery</a><br />
                    Your ProductionCrate email address is the same email you use to log in to Facebook. You should receive a reset email within 2 - 10 minutes. 
                    <br /><br />
                    If you have any questions, please let us know at support@ProductionCrate.com and we will do our best to assist you in a timely manner.
                    </div>
                }
                cta="Reset Password"
                ctaCallback={() => document.location.href = '/password-recovery'}
                ctaClose="Close"
                ctaCloseCallback={() => setShowFacebookWarning(false)}
            />
            <div className="login">
                <>
                    {loading && 
                    <div className="empty-content">
                        <Spinner />
                    </div>
                    }
                    {!loading && showLoginForm && 
                    <form className="form" onClick={event => event.stopPropagation()} onSubmit={handleFormSubmit}>
                        <input type="submit" style={{display: "none"}} />
                        <div className="title m-b-16">Login</div>
                        <div className="input-name m-b-6">Username or Email</div>
                        <input className="input-text m-b-8" type="text" placeholder="Username or Email" onChange={({target}) => { setError(null);setUsername(target.value); }} value={username} />
                        <div className="input-name m-b-6">Password</div>
                        <input className="input-text m-b-12" type="password" placeholder="Password" onChange={({target}) => { setError(null); setPassword(target.value); }} value={password} />
                        <div
                            className="m-b-12 m-l-a forgot-password"
                            onClick={() => navigate('/password-recovery', { replace: true })}
                        >
                            Forgot your password?
                        </div>
                        {error && <div className="error-msg m-b-12">{error}</div>}
                        <ButtonCTA label="Login" labelLoading="Logging in..." onClick={handleEmailLogin} ref={formCTARef} style={{maxWidth: 'unset'}}/>
                        <div className="separator m-b-28">or</div>
                        {false && 
                        <FacebookLogin
                            appId={config.facebookAppId}
                            scope="email,public_profile"
                            onSuccess={handleFacebookLogin}
                            onFail={(error) => {
                                console.debug('Login Failed!', error)
                            }}
                            className="btn cta-facebook m-b-24"
                        >
                            <img className="icon" src="/images/icon.fb.svg" alt="facebook icon" />
                            Login with Facebook
                        </FacebookLogin>
                        }
                        <div className='btn cta-facebook m-b-24' onClick={handleFacebookWarning}>
                            <img className="icon" src="/images/icon.fb.svg" alt="facebook icon" />
                            Login with Facebook
                        </div>
                        <div
                            className="btn cta-google m-b-32"
                            onClick={() => googleLogin()}
                        >
                            <img className="icon" src="/images/icon.google.svg" alt="google icon" />
                            Login with Google
                        </div>
                        {standalone && closeCallback && 
                            <div className="m-t-32 m-b-28" style={{cursor: 'pointer', textDecoration: 'underline', color: '#FFF'}} onClick={() => closeCallback()}>Go Back</div>
                        }
                    </form>
                    }
                    {!loading && showRedirectToApp && 
                        <div className="form fade-in">
                            <Spinner type="logo" loop={false} label={false} style={{position: 'relative', margin: 'auto'}} />
                            <a className="cta" href={showRedirectToApp}>
                            Launch Portal
                            </a>
                            <div className="m-t-32 m-b-28" style={{cursor: 'pointer', textDecoration: 'underline', color: '#FFF'}} onClick={() => document.location.href="/login"}>Go Back</div>
                        </div>
                    }
                </>
                {false && 
                <ReCAPTCHA
                    sitekey={config.recaptchaSiteKey}
                    size="invisible"
                    ref={reCaptchaRef}
                />
                }
            </div>
        </div>
    );
};

export default Login;

