import client from './axiosInstance'
import { getCookie, deleteCookie, setCookie } from './utils';

export const forgetPassword = async (data) => {
  try{
    const response = await client.post('auth/forgot-password', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await client.post('auth/reset-password',  data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUserFn = async (user) => {
  try {
    const response = await client.post('auth/login', user);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const refreshTokenRequest = async (refresh_token) => {
  try {
    const response = await client.post('auth/refresh-token', {refresh_token});
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const deleteUserFn = async () => {
  const response = await client.delete('me')
  return response.data
}


export const subscribeUserFn = async ({ provider, planId }) => {
  const response = await client.post('subscriptions', {
    provider,
    plan_id: planId,
  })
  if(response && response.data){
    return response.data
  }
  console.log("bad response from subscribe [provider, planId, response]", provider, planId, response);
  return false;
}

export const cancelSubscription = async () => {
  const response = await client.delete('subscriptions');
  if(response && response.data){
    return response.data;
  }
  console.log("bad response from cancel subscription", response);
  return false;
  
}

export const getProducts = async ({ campaign }) => {

  let url = '/products';
  if(campaign){
      url += `?campaign_id=${campaign}`;
  }
  const response = await client.get(url);

  if(response && response.data){
    return response.data;
  }
  return false;
}


export const favoritesFn = async (data) => {
  let response = {};
  let favorites = [];

  if(data){
    response = await client.get('me');
    if(response.data.settings && response.data.settings['favorites']){
      favorites = JSON.parse(response.data.settings['favorites']);
    }
    if(favorites.filter(item => item.id === data.id).length === 0){
      favorites.push(data);
      response = await client.put('me/settings', {favorites: JSON.stringify(favorites)});

      return response.data;
    }
    return favorites;

  }else{
    response = await client.get('me');
    if(response.data.settings && response.data.settings['favorites']){
      favorites = JSON.parse(response.data.settings['favorites']);
    }
    return favorites;
  }
}

export const favoritesDelete = async id => {
  let favorites = await favoritesFn();
  favorites = favorites.filter(item => item.id !== id);
  const response = await client.put('me/settings', {favorites: JSON.stringify(favorites)});
  return favorites;
}

export const userFn = async (data) => {
  let response = {};
  try{
    if(data){
      response = await client.put('me', data);
    }else{
      response = await client.get('me');
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const meFn = async (data) => {
  let response = {};
  try{
    if(data){
      response = await client.put('me/settings', data);
    }else{
      response = await client.get('me');
    }
    return response.data;
  } catch (error) {
    console.log("Throw error", error.message);
    //throw error;
  }
}


/*
Members (GET): Listado de miembros del teams al cual pertenece el user
Delete Member (DELETE): Borra un miembro por users_id (solo el owner puede borrar, no puedo borrarse a si mismo)
Invitations
Invitations (GET): Invitaciones enviadas
Invite (POST): Crea una nueva invitación (esto no crea usuarios)
Delete invitation (DELETE)
Resend Invitation (GET): Reenvia el mail de invitación
Accept Invite (GET): Este método lo usas para confirmar una invitación. Tenes que mandar el token que lo recibis como parametro desde el mail. 
*/

export const teamFn = {
  settings: async (data) => {
    let response = {};
      if(data){
        response = await client.put('team/settings', data);
      }else{
        response = await client.get('team/settings');
      }
    return response.data
  },
  members: {
    list: async () => {
      let response = {};
      response = await client.get('team/members');
      return response.data
    },
    delete: async (id) => {
      let response = await client.delete(`team/members/${id}`);
      return response.data
    },
  },
  invitations: {
    list: async () => {
      let response = {};
      response = await client.get('team/invitations');
      return response.data
    },
    delete: async (id) => {
      let response = await client.delete(`team/invite/${id}`);
      return response.data
    },
    invite: async (email) => {
      let response = await client.post(`team/invite`, {email});
      return response.data
    },
    resend: async (id) => {
      let response = await client.get(`team/invite/resend/${id}`);
      return response.data
    },
    accept: async (key) => {
      let response = await client.get(`team/invite/accept/${key}`);
      return response.data
    },
    getEmail:  async (key) => {
      let response = await client.get(`team/invite/get-email/${key}`);
      return response.data
    }
  }


}

/*
export const teamFn = async (data) => {
  let response = {};
    if(data){
      response = await client.put('team/settings', data);
    }else{
      response = await client.get('team/settings');
    }
  return response.data
}
*/

export const getInvoices = async () => {
  const response = await client.get('invoices')
  return response.data
}

export const getAssetsAvailability = async () => {
  const response = await client.get('assets/remaining')
  return response.data
}

export const getAssetsConsumptions = async () => {
    const response = await client.get('assets/consumptions')
    return response.data
}

export const consumeAssets = async (data) => {
    const response = await client.post('assets/consume', data);
    
    return response.data;
}

export const resendVerificationEmail = async () => {
  const response = await client.get('/email/resend');
  return response.data;
}

export const searchAssets = async (search, search_type=false, newUrlScheme) => {
    var query = search
    if(query !== '') {
      query = encodeURIComponent(query).replace(/%20/g, "+");
    } else {
      query = 'new';
    }
    
    var search_url = 'https://vfx.productioncrate.com/search/#!/'+query;
    
    if (newUrlScheme && search_type !== 'tutorials') {
      search_url = `https://app.productioncrate.com/search/${query}?main_category=${search_type.replace('sounds', 'sfx')}`;
    } else if(search_type === 'news') {
      search_url = 'https://news.productioncrate.com/?s='+query;
    } else if(search_type === 'scripts') {
      search_url = 'https://www.productioncrate.com/search/#!/'+query+'&incoming=header';
    } else if(search_type === 'tutorials') {
      search_url = 'https://news.productioncrate.com/?s='+query+'&category_name=how-to-guides&incoming=header';
    } else if(search_type === 'models') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query+'&render_type=models&incoming=header';
    } else if(search_type === 'materials') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query+'&render_type=materials&incoming=header';
    } else if(search_type === 'environments') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query+'&render_type=environments&incoming=header';
    } else if(search_type === '3d') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query;
    } else if(search_type === 'vfx') {
      search_url = 'https://vfx.productioncrate.com/search/#!/'+query+'&incoming=header';
    } else if(search_type === 'mograph') {
      search_url = 'https://vfx.productioncrate.com/search/#!/'+query+'&incoming=header&video_type=1';
    } else if(search_type === 'sounds') {
      search_url = 'https://sfx.productioncrate.com/search/#!/'+query+'&incoming=header&audio_type=0';
    } else if(search_type === 'music') {
      search_url = 'https://sfx.productioncrate.com/search/#!/'+query+'&incoming=header&audio_type=1';
    } else if(search_type === 'graphics') {
      search_url = 'https://graphics.productioncrate.com/search/#!/'+query+'&incoming=header';
    }
    window.location.href = search_url;
    return false;
    //let res = await fetch(`https://d30upb8d426d8z.cloudfront.net/search-json/fc/query/${search.replace(" ", "+")}/order_by/new/broadness/1/v/2/`);
    //return await res.json();
}
export const providerLoginFn = async (provider, credentials) => {
  let url = `auth/${provider}/login`;
  if(provider === 'email'){
      url = `auth/register`;
  }
  try{
    let hasReferral = getCookie('referral');
    if(hasReferral){
      let reference = JSON.parse(decodeURIComponent(hasReferral));

      credentials['referral'] = reference['referral'] || '';
      credentials['referral_data'] = reference['referral_data'] || {};
    }

    const response = await client.post(url, credentials);
    return response.data;
  } catch (error) {
    console.log("RESPONSE", error);
    throw error;
  }
}

export const formatErrorResponse = (response) => {
  return response?.data?.error ?? response?.data?.message ?? response?.statusText ?? 'Something went wrong'
}

const getEnvironment = () => window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";


const partnerStackSignUp = data => {

  if (typeof window.growsumo !== 'object') {
    console.log("Partner Stack wasn't initialized.");
    return;
  }
  let hasReferral = getCookie('referral');

  if(!hasReferral){
    console.log("Signup without partnerstack referrer.");
    return;
  }
  // 1. Populate the growsumo.data object
  window.growsumo.data.name = data.name || '';
  window.growsumo.data.email = data.email || '';
  // In this case, email is how I uniquely identify my customers
  window.growsumo.data.customer_key = `${data.team_id || ''}`;
  
  // Register the signup with PartnerStack
  window.growsumo.createSignup();
  //console.log("Partner Stack signup", window.growsumo.data);
  deleteCookie('referral', '.productioncrate.com');
}

//GA4 event
const sendGAEvent = function (event_name, paid_amount, additional_data={}) {
  if(getEnvironment() === 'development'){
    console.log('event', event_name, {
      'send_to': 'G-78KP68EXMW',
      'value': paid_amount,
      'currency': 'USD',
      ...additional_data
    });
    return;
  }
  if (window.gtag) {
    console.log('event', event_name, paid_amount);
    window.gtag('event', event_name, {
      'send_to': 'G-78KP68EXMW',
      'value': paid_amount,
      'currency': 'USD',
      ...additional_data
    });
  }
};
//GAds event
const sendConversion = function (send_to, paid_amount) {
  if(getEnvironment() === 'development'){
    console.log('event', 'conversion', {
      'send_to': send_to,
      'value': paid_amount,
      'currency': 'USD'
    });
    return;
  }
  if (window.gtag) {
    //console.log('event', 'conversion', paid_amount);
    window.gtag('event', 'conversion', {
      'send_to': send_to,
      'value': paid_amount,
      'currency': 'USD'
    });
  }
};

const dataLayerPush = function (event_name, data) {
  // Push event to data layer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': event_name,
    ...data
  });
}

const convertEventConversion = function (paid_amount, subscriptionId) {
  if(getEnvironment() === 'development'){
    console.log('event', 'conversion', {
      'value': paid_amount,
      'subscriptionId': subscriptionId
    });
    return;
  }
  const convertPlatform = window._conv_q || [];
  convertPlatform.push(["pushRevenue", ""+paid_amount, "1", "100452644"]);
  console.log("convert",convertPlatform,["pushRevenue", ""+paid_amount, "1", "100452644"]);

};

const convertGoalPlanTrigger = function (plan) {

  const goalsIds = {
    sample: {
      free: '100460767',
      monthly: '100460770',
      yearly: '100460775',
    },
    pro: {
      monthly: '100460766',
      yearly: '100460765',
    },
    enterprise: {
      monthly: '100460769',
      yearly: '100460768',
    }
  };


  let goal_id = false;
  let planName = plan.name.toLowerCase();

  if (planName.includes('sample') && !planName.includes('year')) {
    goal_id = goalsIds.sample.monthly;
  } else if (planName.includes('sample') && planName.includes('year')) {
    goal_id = goalsIds.sample.yearly;
  } else if (planName.includes('free')) {
    goal_id = goalsIds.sample.free;
  } else if (planName.includes('pro') && planName.includes('year')) {
    goal_id = goalsIds.pro.yearly;
  } else if (planName.includes('pro') && !planName.includes('year')) {
    goal_id = goalsIds.pro.monthly;
  } else if (planName.includes('enterprise') && planName.includes('year')) {
    goal_id = goalsIds.enterprise.yearly;
  } else if (planName.includes('enterprise') && !planName.includes('year')) {
    goal_id = goalsIds.enterprise.monthly;
  }

  if(!goal_id){
    console.log("goal not found for plan", plan);
    return;
  }else{
    console.log("goal found for plan", plan, goal_id);
  }
  if(getEnvironment() === 'development'){
    console.log('event', 'triggerConversion', {
      'goal_id': goal_id,
      'planName': planName
    });
    return;
  }

  const convertPlatform = window._conv_q || [];
  convertPlatform.push(["triggerConversion", goal_id]);
  console.log("convert",convertPlatform,["triggerConversion", goal_id]);
};
export const Analytics = {
  signup: (paid_amount = 0, userData) => {
    if (getEnvironment !== 'production') {
      //console.log("analytics signup", paid_amount, userData);
    }
    sendConversion('AW-709072149/9-m3CK6M-a8YEJWqjtIC', paid_amount);
    sendGAEvent('sign_up', paid_amount);
    partnerStackSignUp(userData);
  },
  addToCart: (paid_amount = 0) => {
    if (getEnvironment !== 'production') {
      //console.log("add to cart", paid_amount);
    }
    sendConversion('AW-709072149/wmV6CLLtgbAYEJWqjtIC', paid_amount);
    sendGAEvent('plan_selection', paid_amount);
  },
  conversion: (paid_amount = 0, subscriptionId=false) => {
    //GAds event
    sendConversion('AW-709072149/FZ5WCNL8768YEJWqjtIC', paid_amount);
    //GA4 event
    sendGAEvent('purchase', paid_amount, {
      'currency': 'USD',
      'signed_on': true,
      'paying_member': true,
      'page_title': 'New Pro Member'
    });
    //convert.com event:
    convertEventConversion(paid_amount, subscriptionId);
    //send conversion to dataLayer
    dataLayerPush('purchaseConversion', {
      'conversionValue': paid_amount,
      'subscriptionId': subscriptionId
    });
  },
  goalPlan: (plan) => {
    console.log("goal plan", plan);
    convertGoalPlanTrigger(plan);
  }
}


