import React, {useEffect, useState, useRef, useImperativeHandle, forwardRef} from 'react';
import './ButtonCTA.scss';


const ButtonCTA = forwardRef(({label, labelLoading, onClick, disabled=false, secondary, style}, buttonRef) => {

    const [loading, setLoading] = useState(false);
    const [disabledState, setDisabled] = useState(disabled);
    const [labelText, setLabelText] = useState(label);

    useEffect(() => {

        setDisabled(loading);
        
        if (labelLoading) {
            setLabelText(loading?labelLoading:label);
        }
    }, [loading]);
    /*
    useImperativeHandle(ref, () => ({
        setValue(val){
            internalValueSet(val);
        }
    }));
    */

    const handleClick = async (event) => {
        if (loading) {
            return;
        }
        setLoading(true); 
        await onClick();
        setLoading(false);
    }
    return (
        <div className={`button-cta ${disabledState?'disabled':''} ${secondary?'secondary':''}`} style={style} ref={buttonRef} onClick={handleClick}>
            {labelText}
        </div>
    );
});

export default ButtonCTA;

