import React from 'react';

import './Cta.scss';

const Cta = ({label, type='default', disabled, onClick, status }) => {
  const handleClick = () =>{
    if(onClick && typeof onClick === 'function' && !disabled){
      onClick();
    }else{
      console.log("No onClick function provided");
    }
  };
  return (
    <div className={`cta  ${type} ${disabled?'disabled':''} m-t-24 m-b-24 xs:m-t-16 xs:m-b-16`} onClick={handleClick}>{label}</div>
  );
};

export default Cta;
