import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import md5 from 'js-md5';
import { useUserContext } from "../../contexts";
import { searchAssets, subscribeUserFn, meFn } from '../../lib/api';
import useClickable from '../../hooks/useClickable';
import Menu from './Menu';
import Logo from './logo.svg';
import LogoName from './logo.name.svg';
import LogoIcon from './logo.icon.svg';
import { Spinner } from '../Spinner';
import './Header.scss';
import CATEGORIES from './categories.json';

const getEnvironment = () => window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";
const env = getEnvironment();

const BASE_HREF_MAP = {
    'development': '',
    'qa': 'https://user-qa.productioncrate.com',
    'production': 'https://user.productioncrate.com'
}

const Header = ({minimal=false, external=false, newUrlScheme=false}, ref) => {

    const {user, unloadUser, focusMode} = useUserContext();

    const [searchQuery, setSearchQuery] = useState('');
    //const [user, setUser] = useState(null);
    const [assetsAvailable, setAssetsAvailable] = useState(null);
    const [premiumAssetsPolicy, setPremiumAssetsPolicy] = useState(false);
    
    const [banners, setBanners] = useState([]);
    const [bannerToDisplay, setBannerToDisplay] = useState(null);

    const [loading, setLoading] = useState(false);

    const [floatingVisible, setFloatingVisible] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false);
    const [selectorFocus, setSelectorFocus] = useState(false);
    const [categorySelectorExpanded, setCategorySelectorExpanded] = useState(false);
    const [categorySelected, setCategorySelected] = useState('all');
    const baseHref = external?(window.location.hostname==='app.productioncrate.com'?'https://user-qa.productioncrate.com':'https://user.productioncrate.com'):'';

    const [showMenuContent, setShowMenuContent] = useState(false);

    /*
    const DOMAIN_MAP = {
        'app.productioncrate': 'productioncrate',
        'localhost': 'productioncrate',
        'footagecrate': 'footagecrate',
        'soundscrate': 'soundscrate',

    }
    */

    const showBrandSubtitle = ['rendercrate', 'footagecrate', 'graphicscrate', 'soundscrate']
    .some(domain => window.location.hostname.includes(domain));

    useImperativeHandle(ref, () => ({
        assignAssetsAvailable(remaining){
            setAssetsAvailable(remaining);
        }
    }));

    useEffect(() => {
        fetch(`https://${env === 'production'?'rest':'rest-qa'}.productioncrate.com/web/banners`)
        .then(response => response.json())
        .then(data => {
            setBanners(data);
        });
        if (['sfx', 'soundscrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical sounds)");
            setCategorySelected('sounds');
        } 
        else if (window.location.pathname.startsWith("/3D") || ['3d.productioncrate.com', 'rendercrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical 3d");
            setCategorySelected('3d');
        }
        else if (['vfx', 'footagecrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical vfx");
            setCategorySelected('vfx');
        }
        else if (['graphics', 'graphicscrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical graphics");
            setCategorySelected('graphics');
        }
        let path = window.location.pathname;
        if (path.includes('/search')) {
            let searchTerm = '';
            if (window.location.hash) {
                if (['sfx', 'soundscrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical sounds)");
                    setCategorySelected('sounds');
                } 
                else if (window.location.pathname.startsWith("/3D") || ['3d.productioncrate.com', 'rendercrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical 3d");
                    setCategorySelected('3d');
                }
                else if (['vfx', 'footagecrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical vfx");
                    setCategorySelected('vfx');
                }
                else if (['graphics', 'graphicscrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical graphics");
                    setCategorySelected('graphics');
                }
                
                searchTerm = window.location.hash.split('#!/')[1].split('&')[0];
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const mainCategory = urlParams.get('main_category');
                if (['all', 'vfx', 'sounds', '3d', 'graphics'].some(c => c===mainCategory)) {
                    setCategorySelected(mainCategory);
                }
                searchTerm = path.split('/search/')[1].split('?')[0];
            }
            searchTerm.replace('+', ' ');
            if (searchTerm.length>0){
                console.log("SEARCH TERM", searchTerm, searchTerm.replace("+", " "));
                setSearchQuery(searchTerm);
                setSearchFocused(true);
            }
        }
    }, []);

    useEffect(() => {
        //console.log("header user", user);
        if(user && user.team && user.team.subscription && user.team.subscription.plan){
            let tempPlan = user.team.subscription.plan;
            tempPlan.features.forEach(feat => {
                if(feat.name === 'assets-download'){
                    if (feat.policy && feat.policy.name.toLowerCase().includes('free')) {
                        setPremiumAssetsPolicy(false);
                    } else {
                        setPremiumAssetsPolicy(true);
                    }
                }
            });
        }
    }, [user]);
    useEffect(() => {
        if(!user || !banners || banners.length === 0){
            return;
        }
        let seenBanners = {};
        try{
            seenBanners = user.settings && user.settings.banners?JSON.parse(user.settings.banners):{};
        }catch(e){
            console.log("Problem parsing banners", e);
        }
        //console.log("user settings banners", user.settings.banners);
        //console.log("seenBanners", seenBanners);
        let newBanners = banners.filter(banner => !seenBanners.hasOwnProperty(+banner.id));
        //console.log("newBanners", newBanners);
        const blacklist = ["register", "upgrade", "login", "signup"];

        if (newBanners.length > 0 && !blacklist.some(substring => window.location.href.includes(substring))) {
            //console.log("Setting banner to display", newBanners[0]);
            setBannerToDisplay(newBanners[0]);
        }
    }, [user, banners]);

    const hideBanner = (id) => {
        setBannerToDisplay(null);
        let seenBanners = {};
        try{
            seenBanners = user.settings && user.settings.banners?JSON.parse(user.settings.banners):{};
        }catch(e){
            console.log("Problem parsing banners", e);
        }
        seenBanners[+id] = true;
        meFn({banners: JSON.stringify(seenBanners)});
    };

    const handleSearch = (event) => {
        event.preventDefault();

        searchAssets(searchQuery, categorySelected, newUrlScheme);
        //setSearchQuery('');
    };

    const handleLogout = () => {
        unloadUser();
    };


    const reactivateUser = (provider, planId) => {
        setLoading(true);
        let postData = {provider, planId};
        
        subscribeUserFn(postData).then(data => {

            if(data.url){
                document.location.href = data.url;
            } else {
                console.log("Problem while subscribing user ie", data);
                document.location.href = '/register';
            }
            setLoading(false);
        })
        .catch(e => {
            console.log("Problem while subscribing user tc", e);
            setLoading(false);
            document.location.href = '/register';
        });

        

    };
    


    const categorySelector = useClickable(() =>{
        setSelectorFocus(true);
        setCategorySelectorExpanded(true);
    },
    () => {
        setTimeout(() => setSelectorFocus(false), 500);
        setCategorySelectorExpanded(false);
    });

    const toggleCategorySelected = (index) => {
        if (categorySelected === index) {
            setCategorySelected('all');
        } else {
            setCategorySelected(index);
        }
    };

    const selectorText = (category) => {
        let MAP_CAT = {
            'all': 'All Categories',
            'vfx': 'VFXs',
            'mograph': 'Motion Graphics',
            'sounds': 'SFXs',
            'graphics': 'Graphics',
            '3d': '3D',
            'tutorials': 'Tutorials'
        };
        return MAP_CAT[category];
    };
    return (
        <div className={`header ${focusMode?'fade-out':''} ${minimal?'minimal':''} `}>
            {bannerToDisplay &&
            <div className="special_date">
                <span className="content">
                    {bannerToDisplay.url && 
                    <a href={bannerToDisplay.url} target="_blank">
                        {bannerToDisplay.message}
                    </a>
                    }
                    {!bannerToDisplay.url &&
                    <>
                        {bannerToDisplay.message}
                    </>
                    }
                </span>
                <span className="close" onClick={() => hideBanner(bannerToDisplay.id)}>X</span>
            </div>
            }
            <div className="first-row">
                <div className="wrapper">
                <a className="logo" href="https://www.productioncrate.com">
                        <img className="pic" src={`${baseHref}${Logo}`} alt="Logo" />
                        <div className="name">
                            <img src={`${baseHref}${LogoName}`} alt="logo name" />
                            {showBrandSubtitle &&
                            <div className="brand_subtitle">
                                by ProductionCrate
                            </div>
                            }
                        </div>
                    </a>
                    {!minimal && 
                    <form className={`search-bar ${searchFocused || selectorFocus?'focused':''} m-l-a`} id="js-search-form" onSubmit={handleSearch} >
                        <img className="logo" src={`${baseHref}${Logo}`} alt="Logo" />
                            <div className={`chip options-select ${categorySelectorExpanded?'expanded':''}`} ref={categorySelector} >
                                {`${selectorText(categorySelected)}`}
                                <span className="glyphicon glyphicon-down chevron-icon m-l-a"></span>
                                <div className="options">
                                    <div className={`item ${['all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('all')}>
                                        All <span className={`glyphicon glyphicon-${['all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['vfx', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('vfx')}>
                                        VFX & Motions<span className={`glyphicon glyphicon-${['vfx', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['sounds', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('sounds')}>
                                        Sounds <span className={`glyphicon glyphicon-${['sounds', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['graphics', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('graphics')}>
                                        Graphics  <span className={`glyphicon glyphicon-${['graphics', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['3d', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('3d')}>
                                        3D  <span className={`glyphicon glyphicon-${['3d', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                </div>
                            </div>
                            <input 
                                type="text" 
                                value={searchQuery} 
                                onChange={({target}) => setSearchQuery(target.value)} 
                                onFocus={() => setSearchFocused(true)} 
                                onBlur={() => {
                                    if(!searchQuery ){ 
                                        setSearchFocused(!!searchQuery);
                                    }
                                }} 
                                name="query" 
                                placeholder={searchFocused || selectorFocus?`Try searching "new" to see recent uploads`:'Search'}
                                spellCheck="True" 
                                autoComplete="off" 
                                dir="auto" 
                                className={`search-bar-input`}
                            />
                            <span onFocus={() => setSearchFocused(true)} class={`close glyphicon glyphicon-cross ${searchQuery?'visible':''}`} 
                                onClick={() => {
                                    setSearchQuery('');
                                    setTimeout(() => setSearchFocused(false), 350);
                                }}></span>
                            <button type="submit" className="form-btn btn-search">
                                <span className="glyphicon glyphicon-search"></span>
                            </button>
                    </form>
                    }
                    <div className="controls m-l-a">

                        {!premiumAssetsPolicy && user &&
                        <div className="desktop_only">
                            
                            {user.team.last_paid_subscription &&
                            <div className="item show-logged-free accent" style={{cursor: 'pointer'}} onClick={() => reactivateUser(user.team.last_paid_subscription.provider, user.team.last_paid_subscription.plan_id)}>
                                Restore Subscription
                            </div>
                            }
                            {!user.team.last_paid_subscription &&
                            <>
                                {false &&
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <span className="glyphicon glyphicon-credit-card"></span>
                                </a>
                                }
                                {false &&
                                <a className="item show-logged-free" href={`${baseHref}/upgrade/?prov=p`}>
                                    <span className="glyphicon glyphicon-paypal"></span>
                                </a>
                                }
                                <a className="item show-logged-free accent" href={`${baseHref}/upgrade`}>
                                    Upgrade to Pro
                                </a>
                            </>
                            }
                        </div>
                        }
                        {!user &&
                        <>
                            <a className="item show-logged-out" href={`${baseHref}/member`}>
                                Login
                            </a>
                            <a className="item show-logged-out accent" href={`${baseHref}/register`}>
                                Register
                            </a>
                        </>
                        }
                        
                    </div>
                    {user &&
                    <div className="account-menu m-l-a">
                        <span className="glyphicon glyphicon-down"></span>
                        {user && 
                            <div className="username" >
                                {user.username?user.username:user.email}
                            </div>
                        }
                        {!user && 
                            <div className="username" >
                                Account
                            </div>
                        }
                        <div className="profile-pic" >
                            {false && <img className="pic" src="https://cdn.staticcrate.com/profile/1487723/200x200/1000000000.png" /> }
                            <img src={`https://www.gravatar.com/avatar/${md5((user?user.email:'').trim().toLowerCase())}?s=200&d=${encodeURIComponent('https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg')}`} />
                        </div>
                        <div className="submenu" id="js-account-menu">
                            {assetsAvailable !== null && user &&
                            <div className="item head">
                                {assetsAvailable} downloads remaining today.
                            </div>
                            }
                            {user && 
                            <a className="item show-logged-free show-logged-pro" href={`${baseHref}/member`}>
                                <i className="glyphicon glyphicon-user"></i>
                                Member Page
                            </a>
                            }
                            {!user && 
                            <>
                                <a className="item show-logged-out" href={`${baseHref}/login`}>
                                    <i className="glyphicon glyphicon-user"></i>
                                    Login
                                </a>
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-lock"></i>
                                    Register
                                </a>
                                {false &&
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-facebook-square"></i>
                                    Facebook Connect
                                </a>
                                }
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-google-plus-square"></i>
                                    Google Connect
                                </a>
                            </>
                            }
                            {!premiumAssetsPolicy &&
                            <>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade/?prov=p`}>
                                    <i className="glyphicon glyphicon-paypal"></i>
                                    PRO Upgrade (PayPal)
                                </a>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <i className="glyphicon glyphicon-credit-card"></i>
                                    PRO Upgrade (credit/Debit)
                                </a>
                            </>
                            }
                            {user &&   
                                <div className="item show-logged-free show-logged-pro" onClick={handleLogout}>
                                    <i className="glyphicon glyphicon-lock"></i>
                                    Logout
                                </div>
                            }
                        </div>
                    </div>
                    }
                    {!premiumAssetsPolicy && user &&
                    <div className="controls mobile_only m-l-a" style={{width: '100%', justifyContent: 'center'}}>
                        {user.team.last_paid_subscription &&
                        <div className="item show-logged-free accent" style={{cursor: 'pointer', minWidth: '240px'}} onClick={() => reactivateUser(user.team.last_paid_subscription.provider, user.team.last_paid_subscription.plan_id)}>
                            Restore Subscription
                        </div>
                        }
                        {!user.team.last_paid_subscription &&
                        <>
                            {false &&
                            <a className="item show-logged-free" style={{cursor: 'pointer', minWidth: '240px'}} href={`${baseHref}/upgrade`}>
                                <span className="glyphicon glyphicon-credit-card"></span>
                            </a>
                            }
                            {false &&
                            <a className="item show-logged-free" style={{cursor: 'pointer', minWidth: '240px'}} href={`${baseHref}/upgrade/?prov=p`}>
                                <span className="glyphicon glyphicon-paypal"></span>
                            </a>
                            }
                            <a className="item show-logged-free accent" style={{cursor: 'pointer', minWidth: '240px'}} href={`${baseHref}/upgrade`}>
                                Upgrade to Pro
                            </a>
                        </>
                        }
                    </div>
                    }
                    {!minimal &&
                        <img alt="menun icon" src={`${baseHref}/images/icon.burger.svg`} className="menu-bar" onClick={() => setFloatingVisible(true)} />
                    }
                </div>
            </div>
            {!minimal && 
            <div className="second-row">
                <div className="wrapper" style={{position: 'relative'}}>
                    <Menu visible={floatingVisible} closeCallback={() => setFloatingVisible(false)} external={external} newUrlScheme={newUrlScheme} />                    
                </div>
            </div>
            }
            {user && user.email_verified === false && 
            <div className="banner">
                <div className='content' onClick={() => document.location.href='/member/email-verify'}>
                Action Required: Your email address has not been verified yet. Click <a href='/member/email-verify'>here</a> to resend the verification email
                </div>
            </div>
            }
            {loading &&
            <div style={{background: 'rgba(0,0,0,0.9)', width: '100vw', height: '100vh', top: '0', left: '0', position: 'fixed', zIndex: '9', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner label="Reactivating subscription..." />
            </div>
            }
        </div>
    );
};

export default forwardRef(Header);

