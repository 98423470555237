import React, {useState, useEffect, useRef} from 'react';
import { Modal } from '../Modal';
import './Questionnaire.scss';


const Questionnaire = ({user, visible, ctaCallback, expensiveCallback, ctaCloseCallback}) => {
    const notesRef = {
        a: useRef(null),
        b: useRef(null),
        c: useRef(null),
        d: useRef(null)
    }

    //const [showModal, setShowModal] = useState(visible);
    const [canProceed, setCanProceed] = useState(false);
    const [reason, setReason] = useState(0);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [started_date, setStartedDate] = useState('');
    const [plan, setPlan] = useState('');

    const REASON_MAP = {
        1: 'We don’t have what you’re looking for?',
        2: 'Something isn’t working for you?',
        3: 'You’ve found an alternative',
        4: 'Too expensive',
        5: 'You no longer need the service',
        6: 'Other'
    };

    useEffect(() => {
        setUsername(user.username);
        setEmail(user.email);
        setStartedDate(user.team.subscription.started_at.split('T')[0]);
        setPlan(`${user.team.subscription.plan.product.name+' '+user.team.subscription.plan.name} ( $${user.team.subscription.plan.price} per ${user.team.subscription.plan.periodicity_type} )`);
    }, []);

    const handleCtaClick = async () => {
        if(!canProceed){
            return;
        }
        try{
            await submitQuestionnaire();
        }catch(e){
            console.log(e);
        }
        ctaCloseCallback && ctaCloseCallback(false);
        if(reason === 4 && expensiveCallback){
            expensiveCallback();
            return;
        }
        if (ctaCallback) {
            ctaCallback();
        }
    };

    const optionSelect = (option_index) => {
        setCanProceed(true);
        setReason(option_index);
        notesRef.a.current.value='';
        notesRef.b.current.value='';
        notesRef.c.current.value='';
        switch(option_index){
            case 1:
                notesRef.a.current.focus();
                break;
            case 2:
                notesRef.b.current.focus();
                break;
            case 3:
                notesRef.d.current.focus();
                break;
            case 6:
                notesRef.c.current.focus();
                break;
        }
    };

    const submitQuestionnaire = async () => {
        let note = "";
        switch(reason){
            case 1:
                note = notesRef.a.current.value;
                break;
            case 2:
                note = notesRef.b.current.value;
                break;
            case 3:
                note = notesRef.d.current.value;
                break;
            case 6:
                note = notesRef.c.current.value;
                break;
        }

        return sendGForm({username, email, plan, started_date, reason: REASON_MAP[reason], note});
    };

    const sendGForm = (data) => {
        console.log("to send data:", data);
        
        // URL del formulario de Google Forms que termina en /formResponse
        var googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSf3Ga-DO7ixqmuSbnEyFReCiPwX2FEINwBy6uRA01R9FpjVOQ/formResponse';
        
        // IDs de los campos del formulario. Reemplázalos con los tuyos.
        var entryIDs = {
            username: 'entry.674977585',
            email: 'entry.790349041',
            plan: 'entry.1849155168',
            started_date: 'entry.1898619690',
            reason: 'entry.1982830785',
            note: 'entry.1381715405'
        };
      
        // Crear objeto FormData y añadir los datos
        var formData = new FormData();
        formData.append(entryIDs.username, data.username);
        formData.append(entryIDs.email, data.email);
        formData.append(entryIDs.plan, data.plan);
        formData.append(entryIDs.started_date, data.started_date);
        formData.append(entryIDs.reason, data.reason);
        formData.append(entryIDs.note, data.note);
        
      
        // Hacer una solicitud POST al formulario de Google
        return fetch(googleFormURL, {
            method: 'POST',
            body: formData,
            mode: 'no-cors' // Importante para evitar problemas de CORS con Google Forms
        });
    };
    
    return (
        <div className={`questionnaire` } >
            <Modal 
                visible ={visible}
                title="Let's Make Things Better"
                msg={
                    <div className="form " >
                        {`Can you share why you're considering canceling your subscription?`}
                        <br />
                        <div className="questions m-t-18" >
                            <label htmlFor="reason-1">
                                <input type="radio" id="reason-1" name="reason" value="1" onChange={(e) => optionSelect(1)} />
                                <b>We don’t have what you’re looking for?</b>
                            </label>
                            <textarea placeholder='Please tell us more about it...' ref={notesRef.a} className={`${reason===1?'visible':''}`} ></textarea>
                            <label htmlFor="reason-2">
                                <input type="radio" id="reason-2" name="reason" value="2" onChange={(e) => optionSelect(2)} />
                                <b>Something isn’t working for you?</b>
                            </label>
                            <textarea placeholder='Please tell us more about it...' ref={notesRef.b} className={`${reason===2?'visible':''}`} ></textarea>
                            <label htmlFor="reason-3">
                                <input type="radio" id="reason-3" name="reason" value="3" onChange={(e) => optionSelect(3)} />
                                <b>You’ve found an alternative</b>
                            </label>
                            <textarea placeholder='Who?' ref={notesRef.d} className={`${reason===3?'visible':''}`} ></textarea>
                            <label htmlFor="reason-4">
                                <input type="radio" id="reason-4" name="reason" value="4" onChange={(e) => optionSelect(4)} />
                                <b>Too expensive</b>
                            </label>
                            <label htmlFor="reason-5">
                                <input type="radio" id="reason-5" name="reason" value="5" onChange={(e) => optionSelect(5)} />
                                <b>You no longer need the service</b>
                            </label>
                            <label htmlFor="reason-6">
                                <input type="radio" id="reason-6" name="reason" value="6" onChange={(e) => optionSelect(6)} />
                                <b>Other</b>
                            </label>
                            <textarea placeholder='Please tell us more about it...' ref={notesRef.c} className={`${reason===6?'visible':''}`} ></textarea>
                        </div>
                        <br />
                    </div>
                }
                showCloseIcon={true}
                cta={canProceed?"Proceed to Cancel":"Answer above to proceed"}
                ctaDisabled={!canProceed}
                ctaCallback={() => handleCtaClick()}
                ctaClose="Back"
                ctaCloseCallback={() => ctaCloseCallback(false)}
                overlayCallback={() => ctaCloseCallback(false)}
                style={{height: 'max-content'}}
            />
        </div>
    );
};

export default Questionnaire;

