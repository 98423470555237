
import './Footer.scss';


const Footer = () => {


    return (
        <div className="footer">
            <div className="column">
            </div>
            <div className="column">
                <a href="https://www.productioncrate.com/terms.html">Copyright © ProductionCrate 2023</a>
                <a href="mailto:support@productioncrate.com">Support@productioncrate.com</a>
                <a href="https://productioncrate.partnerstack.com/" target="_blank" rel="noopener">Affiliate Program</a>
                <a href="https://www.productioncrate.com/privacy.html">Privacy Policy</a>
                <a href="https://www.productioncrate.com/terms.html">Terms</a>
            </div>
        </div>
    );
};
    
export default Footer;