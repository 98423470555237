import React, {useState, useEffect, useRef} from 'react';
import config from '../../lib/config';
import { useUserContext } from "../../contexts";
import { teamFn } from '../../lib/api';
import { getCookie, deleteCookie, setCookie } from '../../lib/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Signup } from '../Signup';
import { Login } from '../Login';
import '../../styles/layout.scss';
import './InviteAccept.scss';
import { Header } from '../Header';
import {ButtonCTA} from '../Form';
import { Spinner } from '../Spinner';

const InviteAccept = ({}) => {

    const formCTARef = useRef(null);

    let { code } = useParams();

    //Custom Hooks
    const {user, setUserToken, isAuthenticating} = useUserContext();

    const [inviteCode, setInviteCode] = useState(null);

    const [defaultEmail ,setDefaultEmail] = useState("");

    const [showNextStep ,setShowNextStep] = useState("accept");

    const navigate = useNavigate();

    useEffect(() => {

        //console.log("CODE", code);
        if(code) {
            setInviteCode(code);
        }
        //setDefaultEmail("nachourpi+testteam4@gmail.com");
        teamFn.invitations.getEmail(code).then(data => {
            //console.log("INVITE INFO", data);
            setDefaultEmail(data.email);
        }).catch(err => {
            setShowNextStep("error");
        });
    }, [code]);


    //If we found an eligible account associated with that username, we've sent password reset instructions to the primary email address on the account.
    useEffect(() => {
        if (!user || !inviteCode) {
            return;
        }
        //console.log("USER is logged in should accept now?", user);
        setShowNextStep("accepting");
        if(showNextStep === "accepting"){
            return;
        }
        handleAcceptInvite();
        //navigate('/member', { replace: true });
    }, [user, inviteCode]);

    const handleAcceptInvite = async () => {
        if(!inviteCode){
            return;
        }
        try{
            await teamFn.invitations.accept(inviteCode);

            setShowNextStep("gohome");
        }catch(e){
            console.log(e);
            setShowNextStep("error");
        }
        //setCookie('teamInvite', inviteCode, 365);
    };

    const redirectMember = () => {
        window.location.href = "/member";
    };
    
    const redirectRegister = () => {
        navigate('/register', { replace: true });
    };

    const handleClickRegister = () => {
        //console.log("register click");
        setShowNextStep("signup");
    };

    const handleClickLogin = () => {
        //console.log("register click");
        setShowNextStep("login");
    }
    
    const signupSuccessCallback = (token) => {
        if (token) {
            //console.log("This was a signup callback only");
            setUserToken(token);
            setShowNextStep("accepting");
            //we just close the flow here, as this function will be executed again when the user from the token is loaded /me
            return;
        }else{
            console.log("error on signup");
        }
    }

    //TODO caso en que user is authenticating -> show loading message
    //TOD case when user is logged in already -> trigger handleAcceptInvite and show a success mesage with a continue button
    
    
    return (
        <div className="layout">
            <Header search={() => null} />
            <div className="password-recovery" >
                {inviteCode && showNextStep === "accept" &&
                <div className="form">
                    <input type="submit" style={{display: "none"}} />
                    <div className="title m-b-16">Accept Invitation</div>
                    {!!defaultEmail && 
                    <>
                        <div className="explanation m-b-12">To join this PRO membership you will have to:</div>
                        <ButtonCTA label="Register" labelLoading="Redirecting to register page..." onClick={handleClickRegister} />
                        <div className="m-t-16 m-b-16" style={{color: 'white'}}>OR</div>
                        <ButtonCTA label="Login" secondary Loading="Redirecting to login page..." onClick={handleClickLogin} />
                    </>
                    }
                    {!!!defaultEmail && 
                        <Spinner style={{marginTop: '32px'}} label={"Loading invitation..."} />
                    }
                </div>
                }
                {showNextStep && showNextStep === "signup" && 
                    <Signup 
                        closeCallback={() => setShowNextStep('accept')} 
                        signupSuccessCallback={signupSuccessCallback}
                        defaultEmail={defaultEmail}
                    />
                }
                {showNextStep && showNextStep === "login" && 
                    <Login 
                        standalone={true}
                        closeCallback={() => setShowNextStep('accept')}
                    />
                }
                {showNextStep && showNextStep === "accepting" && 
                    <div className="form">
                        <div className="title m-b-16">Joining team</div>
                        <Spinner style={{marginTop: '32px'}} label={"Please hold on a moment..."} />
                    </div>
                }
                {showNextStep && showNextStep === "gohome" && 
                    <div className="form">
                        <div className="title m-b-16">Invitation Accepted!</div>
                        <div className="explanation m-b-12">You have successfully joined this PRO membership</div>
                        <ButtonCTA label="Continue" labelLoading="Redirecting to home page..." onClick={redirectMember} />
                    </div>
                }
                {showNextStep && showNextStep === "error" && 
                    <div className="form">
                        <div className="title m-b-16">Oh oh!...</div>
                        <div className="explanation m-b-12">Something's not right with this invitation.</div>
                        <ButtonCTA label="Continue to Signup" labelLoading="Redirecting to register page..." onClick={redirectRegister} />
                    </div>
                }
            </div>
        </div>
    );
};

export default InviteAccept;

