import React, {useState, useImperativeHandle, forwardRef} from 'react';
import { meFn } from '../../lib/api';
import { Spinner } from '../Spinner';
import { setCookie } from '../../lib/utils';
import './FormatSelection.scss';


const FormatSelection = ({settings, settingsCallback}) => {

    const [format, setFormat] = useState(settings && settings.videoFormat?settings.videoFormat:null);

    const [preferredBackground, setPreferredBackground] = useState(settings && settings.preferredBackground?settings.preferredBackground:null);

    const [loading, setLoading] = useState(false);

    const selectOption = async (data) => {
        setLoading(true);
        
        await meFn(data);
        
        
        if(data.videoFormat){
            setFormat(data.videoFormat);
            //Set dpref to both localStorage and cookie
            localStorage.setItem('dpref', data.videoFormat);
            setCookie('dpref', data.videoFormat, 365);
        }
        if(data.preferredBackground){
            setPreferredBackground(data.preferredBackground);
        }
        
        await settingsCallback();
        
        setLoading(false);

    };

    return (
        <div className="format-selection m-h-a row-section">
            <div className="title ">Choose your preferred HD VFX download format:</div>
            {loading && 
            <div className={`loading visible`}>
                <Spinner />
            </div>
            }
            <div className="list m-h-a">
                
                <div className={`item ${format==='mov'?'selected':''}`} onClick={() => selectOption({videoFormat:'mov'})}>
                    <div className="radio m-b-16"></div>
                    <div className="name m-b-12">MOV - PNG Codec</div>
                    <div className="description m-b-16">Best for feature films, advanced compositing, and HD big-screen broadcasting.</div>
                    <div className="instructions">
                    **This option may be too much for some previewers, but should look beautiful once imported directly into most editing suites. Some users may need to install QuickTime to use this option.
                    </div>
                </div>
                <div className={`item ${format==='seq'?'selected':''}`} onClick={() => selectOption({videoFormat:'seq'})}>
                    <div className="radio m-b-16"></div>
                    <div className="name m-b-12">PNG Sequence</div>
                    <div className="description m-b-16">Optimal for online videos, online streaming, and game development. Compatible in most editing suites.</div>
                    <div className="instructions">
                    **Simply unzip and drop the image sequence folder into your timeline. If you use DaVinci, Fusion, or Vegas, this option is for you!
                    </div>
                </div>
                <div className={`item ${format==='prores'?'selected':''}`} onClick={() => selectOption({videoFormat: 'prores'})}>
                    <div className="radio m-b-16"></div>
                    <div className="name m-b-12">MOV - ProRes 4444</div>
                    <div className="description m-b-16">
                    Has native apple support, and allows for easy previewing on Final Cut and iMovie on Catalina
                    </div>
                    <div className="instructions">
                    **Simply unzip the file after downloading and drop it into your project. To save even more time you can set your browser to automatically extract zip files (if that hasn't already been enabled).
                    </div>
                </div>
                <div className={`item ${format==='mp4'?'selected':''}`} onClick={() => selectOption({videoFormat: 'mp4'})}>
                    <div className="radio m-b-16"></div>
                    <div className="name m-b-12">MP4 - H.264 Codec</div>
                    <div className="description m-b-16">Optimal for some lightweight editing platforms, or if you prefer to key out greenscreens yourself!</div>
                    <div className="instructions">
                    **To use this option you must use a blend mode, or the chroma-key option in your video editing software.
                    </div>
                </div>
            </div>
            {format === 'mp4' && 
                <div className="list-small">
                    <div className="item">Preferred MP4 Background:</div>
                    <a className="item" onClick={() => selectOption({preferredBackground: 'mp4'})}>
                        <span className={`glyphicon glyphicon-${preferredBackground=='mp4'?'check':'unchecked'}`}></span>
                        Suggested (Default)
                    </a>
                    <a className="item" onClick={() => selectOption({preferredBackground: 'mp4gs'})}>
                        <span className={`glyphicon glyphicon-${preferredBackground=='mp4gs'?'check':'unchecked'}`}></span>
                        GreenScreen (Use Chroma Key)
                    </a>
                    <a className="item" onClick={() => selectOption({preferredBackground: 'mp4matte'})}>
                        <span className={`glyphicon glyphicon-${preferredBackground=='mp4matte'?'check':'unchecked'}`}></span>
                        Matte/Black BG (Use ScreenBlend Mode)
                    </a>

                </div>
            }
        </div>
    );
};

export default FormatSelection;

