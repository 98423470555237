import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUserContext } from "../../contexts";
import { subscribeUserFn, getProducts, Analytics } from '../../lib/api';
import { Signup } from '../Signup';
import { Header } from '../Header';
import { Faq } from '../Faq';
import { Spinner } from '../Spinner';
import { Footer } from '../Footer';
import { Modal } from '../Modal';
import productsMetadata from '../../constants/plans';
import { AnimatedCounter } from  'react-animated-counter';
import { getCookie } from '../../lib/utils';
import config from '../../lib/config';

import './Plans.scss';

const Plans = forwardRef(({standalone = false}, plansSelectionRef) => {

  //Custom Hooks
  const {user, isAuthenticating} = useUserContext();
  let { campaign, msg } = useParams();
  const [searchParams] = useSearchParams();
  
  const location = useLocation();
  const navigate = useNavigate();

  //Ref Hooks
  const plansRef = useRef(null);
  const signupRef = useRef(null);

  
  //State Hooks
  //State to control the loading spinner
  const [showLoading, setShowLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState('Loading...');

  const [showTitle, setShowTitle] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  //State to control the payment provider
  const [provider, setProvider] = useState('stripe');
  const [showProviderSwitch, setShowProviderSwitch] = useState(true);

  //State to control/filter what plans to show Month/Year (for the moment)
  const cohortVersion = searchParams.get('v');
  const [planPeriod, setPlanPeriod] = useState(cohortVersion==='B'?'Year':'Month');

  //State to indicate if the user click in one of the plans to subscribe
  const [selectedPlan, setSelectedPlan] = useState(null);

  //Flag to show/hide the user signup form
  const [showSignup, setShowSignup] = useState(false);
  const [retryScrollToSignup, setRetryScrollToSignup] = useState(0);

  //Used to control the donator/free toggle switch
  const [donatorCheck, setDonatorCheck] = useState(true);

  //This is the list of products that will be shown in the plans section
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);

  //Flags usef to render claim subtitle and plans_period switch
  const [areMonthlyPlans, setAreMonthlyPlans] = useState(false);
  const [areAnnualPlans, setAreAnnualPlans] = useState(false);
  
  //Flag to indicate if the user has a plan already
  const [userHasPlan, setUserHasPlan] = useState(false);

  //Syncronization flag used to listen when both user and products are already loaded
  const [readyFlag, setReadyFlag] = useState(0);

  //Show upgrade confirmation modal
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);
  const [upgradeSettings, setUpgradeSettings] = useState(null);
  const [upgradeInProgress, setUpgradeInProgress] = useState(false);

  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);

  const [upgradeTitle, setUpgradeTitle] = useState(false);

  
  //USeEffects listeners
  useEffect(() => {
    if(campaign && campaign === 'free') {
        campaign = null;
    }

    //getProducts({campaign}).then(data => console.log("By API DATA", data));
    let wasRegistered = localStorage.getItem('crateRegistration') || getCookie('crateRegistration');
    const localToken = localStorage.getItem(config.sessionTokenLocalStorageKey) || getCookie(config.sessionTokenCookieKey);
    if(wasRegistered && localToken){
        wasRegistered = +wasRegistered;
        const msPerWeek = 7 * 1000 * 60 * 60 * 24;
        const dateNow = Date.now();
        if(dateNow - wasRegistered > msPerWeek && !campaign){
            //console.log("user older than a week", new Date(wasRegistered));
            campaign = 'MeganpZQLY';
        }
    }

    getProducts({campaign}).then(data => {
        console.log("setting original products");
     setOriginalProducts(data);
    })
    .catch(e => {
        console.log("Problem while getting products", e, {campaign});
    });
  }, []);

  useEffect(() => {
    if (readyFlag<2) {
        //console.log("still waiting user and products to be both ready");
        return;
    } 
    //console.log("all set, we got user and product, now we can filter them and remove user plan", readyFlag);
    let filters = [];
    if (user && user.team && user.team.subscription && user.team.subscription.state==='active' && user.team.subscription.plan) {
        setUserHasPlan(true);
        //except for free signups, prevent from subscribing to a different periodicity type
        if (user.team.subscription.plan.name.toLowerCase() != 'free') {
            //console.log("add a periodicity type filter to products");
            filters.push(p => p.periodicity_type===user.team.subscription.plan.periodicity_type);
            //Warning: this might result in a race condition leading not to show any plan. Not truly sure about this, but just in case something weird happens, take a look here
            setPlanPeriod(user.team.subscription.plan.periodicity_type);
        }

        //Avoid subscribing to a plan that is cheaper than the current one
        filters.push(p => p.price >= user.team.subscription.plan.price);
    }

    let newProducts = filterProducts(originalProducts, filters);

    //determine if we have monthly &/or annual plans
    let newPlans = [];
    newProducts.forEach(p => {
        newPlans.push(...p.plans);
    });

    setAreMonthlyPlans(newPlans.some(p => p.periodicity_type==="Month"));
    setAreAnnualPlans(newPlans.some(p => p.periodicity_type==="Year"));
    
    if(newProducts.length>0) {
        setProducts(newProducts);
    }
  }, [readyFlag]);

  useEffect(() => {
    //console.log("PRODUCTS TO SHOW", products);
    if(campaign && campaign === 'free') {
       setTimeout(() => setDonatorCheck(false), 400);
    }
  }, [products]);

  useEffect(() => {
    if(originalProducts.length === 0){
        return;
    }
    if(campaign && campaign !== 'free') {
        if(originalProducts && originalProducts.length == 1 && originalProducts[0].plans.length == 1) {
            console.log("ORIGINAL PRODUCTS", originalProducts[0].plans[0].periodicity_type);
            setPlanPeriod(originalProducts[0].plans[0].periodicity_type);
        }
    }
    //console.log("Im here!!", readyFlag, originalProducts);
    setReadyFlag(prev => prev+1);
  }, [originalProducts]);

  useEffect(() => {
    if(isAuthenticating){
        return;
    }
    if (user && user.team && user.team.subscription && !selectedPlan && location.pathname.includes('register')) {
        //console.log("USERRRR", user);
        document.location.href = '/upgrade';
        return;
    }
    if(!user && location.pathname.includes('upgrade')){
        document.location.href = '/register';
        return;
    }
    //setTimeout(() => { console.log("delayed readyFlag"); setReadyFlag(prev => prev+1);}, 3000);
    setReadyFlag(prev => prev+1);
    if (user && user.team && user.team.subscription && user.team.subscription.state==='active') {
        if (user && user.team && user.team.subscription && user.team.subscription.paypal) {
            setProvider('paypal');
            setShowProviderSwitch(false);
        }
        if (user && user.team && user.team.subscription && user.team.subscription.stripe) {
            setProvider('stripe');
            setShowProviderSwitch(false);
        }
    }
    setTimeout(() => setShowTitle(true), 750);
  }, [isAuthenticating, user]);

  useEffect(() => {
    //console.log("provider - selectedPlan - user", provider, selectedPlan, user);
    //Hide upgrade modal
    setConfirmUpgrade(false);
    if(user && selectedPlan && userHasPlan){
        setShowLoading(true);
        setShowSignup(false);
        subscribeUser();
    }
  }, [userHasPlan, selectedPlan, user]);
  
  useEffect(() => {
    //console.log({originalProducts});
    //console.log([planPeriod, donatorCheck]);
    let newProducts = originalProducts.map(prod => {
        let filter = (prod.hasFree && !donatorCheck)?'free':planPeriod;

        //console.log("FILTER", {filter: filter, hasFree: prod.hasFree, donatorCheck: donatorCheck}, filter);
        if(filter === 'free'){
            prod.selectedPlan = prod.plans.find(p => p.name.toLowerCase() === filter);
        }else{
            prod.selectedPlan = prod.plans.find(p => p.periodicity_type === filter && p.name.toLowerCase() !== 'free');
        }
        
        return prod;
    });
    if(newProducts.length>0) {
        setProducts(newProducts);
    }
    

  }, [planPeriod, donatorCheck]);
  
  useEffect(() => {
    //we use retryScrollToSignup to retry scrolling at least 1 waiting for signupRef to be ready
    if(showSignup && retryScrollToSignup <=2 ){
        if(!signupRef.current){
            console.debug("no signup ref... retrying");
            setTimeout(() => setRetryScrollToSignup(prev => prev+1), 200+retryScrollToSignup*200);
            return;
        }
        signupRef.current.scrollIntoView();
    }
  }, [showSignup, retryScrollToSignup]);

  useEffect(() => {
    if(!msg || msg === 'retry'){
        return;
    }
    if(msg === 'paypal') {
        setProvider('paypal');
        return;
    }
    setUpgradeTitle(msg);
  }, [msg]);

  useEffect(() => {
    if(!user || !userHasPlan || msg !== 'retry'){
        return;
    }
    const upgradeRetryPlanId = +searchParams.get('plan_id');
    const upgradeRetryProvider = searchParams.get('provider');

    if(upgradeRetryPlanId && upgradeRetryProvider === 'stripe'){
        console.log("READY TO UPGRADE", user, upgradeRetryPlanId);
        setProvider(upgradeRetryProvider);
        choosePlan(upgradeRetryPlanId);
    }
  }, [msg, user, userHasPlan]);


  useImperativeHandle(plansSelectionRef, () => ({
    setConfirmUpgradeValue(plan_id){
        //console.log("confirm upgrade", plan_id);
        setConfirmUpgrade(plan_id);
    },
    setUpgradeSettingsValue(from,to){
        setUpgradeSettings({
            from:from,
            to:to,
        })
    },
    doUpgradeSubscription(plan_id){
        setSelectedPlan(plan_id);
        setUpgradeInProgress(true);
    },
  }));


  //Custom handlers
  const filterProducts = (data, planFilters) => {
    let newProducts = data.map((prod, i) => {
        prod.metadata = productsMetadata[i];

        if(prod.name === 'PRO') {
            prod.metadata = productsMetadata[1];
        }
        if(prod.name === 'ENTERPRISE'){
            prod.metadata = productsMetadata[2];
        }

        planFilters.forEach(filter => {
            prod.plans = prod.plans.filter(filter);
        });
        prod.hasFree = prod.plans.filter(p => p.name.toLowerCase() === 'free').length>0;

        let filter = (prod.hasFree && !donatorCheck)?'free':planPeriod;
        if(filter === 'free'){
            prod.selectedPlan = prod.plans.find(p => p.name === filter);
        }else{
            prod.selectedPlan = prod.plans.find(p => p.periodicity_type === filter && p.name.toLowerCase() !== 'free');
        }

        return prod;
    });
    return newProducts;
  };

  const toggleProvider = () => {
    setProvider(provider=='paypal'?'stripe':'paypal');
  };
  const togglePlanPeriod = () => {
    setPlanPeriod(planPeriod=='Month'?'Year':'Month');
  };

  const toggleDonate = () => {
    setDonatorCheck(!donatorCheck);

  };
  const doNotDonate = () => {
    
    plansRef.current.scrollIntoView();
    setTimeout(() => setDonatorCheck(false), 600);
  };

  const searchPlan = plan_id => {
    let plan = null;
    originalProducts.forEach(prod => {
        if(plan){
            return;
        }
        plan = prod.plans.find(p => +p.id === +plan_id);
    });
    return plan;
  };

  const choosePlan = (plan_id) => {
    if (user && userHasPlan) {
        console.log("Uaser has a plan let's upgrade");
        //get plan filterinf originalProducts with plan_id
        let plan = searchPlan(plan_id);
        if(!plan){
            console.log("Plan "+plan_id+" not found", originalProducts);
            //TODO show some meaningful error message to the user
            return;
        }

        //get current plan from logged in user
        let currentPlan = null;
        if (user && user.team && user.team.subscription && user.team.subscription.plan) {
            currentPlan = user.team.subscription.plan;
        }
        //if current plan is cheaper than the selected one, show confirmation modal
        //console.log("CURRENT PLAN", currentPlan);
        if(plan && currentPlan && currentPlan.price > 0 && currentPlan.price < plan.price){
            console.log("Current plan is cheaper than the selected one, show confirmation modal. That's fine");
            //set message exaplainging the price upgrade they will pay


            setUpgradeSettings({
                from:currentPlan.price,
                to:plan.price,
            })
            setConfirmUpgrade(plan_id);
            return;
        }
        //TODO: show a menaninguful error message to the user
    }
    setSelectedPlan(plan_id);
    
    let selectedPlan = searchPlan(plan_id);
    Analytics.addToCart(selectedPlan?selectedPlan.price:0);
    if(!user){
        setShowSignup(true);
    }
  };

  const hideSignup = () => {
    setShowSignup(false);
  };

  const signupSuccessCallback = () => {
    setShowLoading(true);
    setShowSignup(false);
    if(selectedPlan ){
        console.log("Plan selected, subscription will happen");
    }else{
        console.log("No plan selected, nothing to do");
    }
  }
  const subscribeUser = () => {
    let plan;
    if (user && user.team && selectedPlan) {
        plan = searchPlan(selectedPlan);
        Analytics.signup(plan?plan.price:0, {
            name: `${user.first_name || ''} ${user.last_name || ''} (${user.username || ''})`,
            email: user.email,
            team_id: user.team.id
        });
    }

    
    let postData = {provider, planId: selectedPlan};

    //if(!donatorCheck){
        //Free plan <- that's wrong... the user could have unchecked it and yet selectedf a pro plan. commenting all...
        //delete postData.provider;
    //}
    if(plan && plan.price === 0){
        //free registration, nothing to do but get redirected to member area
        Analytics.goalPlan(user.team.subscription.plan);
        setTimeout(() => document.location.href='/member', 1000);
        return;
    }
    //console.log("State before subscribing: (doantor, provider, planId)", donatorCheck, provider, selectedPlan);
    subscribeUserFn(postData).then(data => {
        console.log("DATA!!!", data);
        setUpgradeInProgress(false);
        if(data.url && data.type === 'setup'){
            setUpdatePaymentMethod(data.url);
            setConfirmUpgrade(false);
        } else if(data.url && data.type !=='success'){
            setLoadingLabel(<>If you don't get automatically redirected: <a href={data.url} style={{textDecoration: 'underline'}}>Click here.</a></>)
            document.location.href = data.url;

        } else if(data.type === 'success' && data.url && !userHasPlan) {
            setLoadingLabel(<>If you don't get automatically redirected: <a href={data.url} style={{textDecoration: 'underline'}}>Click here.</a></>)
            document.location.href = data.url;
        }else if(data.type ==='success') {
            setShowLoading(false);
            setShowMessage(true);
        }
    })
    .catch(e => {
        console.log("Problem while subscribing user", e);
        setShowLoading(false);
    });

  };

  if(standalone){
    return (
        <div ref={plansSelectionRef} >
            <Modal 
                visible ={!!confirmUpgrade}
                title="Confirm Your Plan Upgrade"
                msg={
                    <div style={{textAlign: 'left'}}>
                        Please read to upgrade your account. Here are the details of the changes to your billing:<br />
                        {upgradeSettings && 
                        <ul style={{listStyleType: 'disc', marginTop: '12px', listStylePosition: 'inside'}}>
                            <li>You're moving from our ${upgradeSettings.from}/{`${areMonthlyPlans?'month':'year'}`} plan to the ${upgradeSettings.to}/{`${areMonthlyPlans?'month':'year'}`} plan.</li>
                        </ul>
                        }
                        <br/>
                        If you have any questions about your upgrade or billing details, please reach out to our support team <a href='mailto:support@productioncrate.com' target='_blank'>Support@ProductionCrate.com</a>
                    </div>
                }
                cta="Upgrade"
                ctaCallback={() => { setSelectedPlan(confirmUpgrade); setUpgradeInProgress(true); }}
                ctaClose="Cancel"
                ctaCloseCallback={() => setConfirmUpgrade(false)}
                style={{height: 'max-content'}}
            />
            <Modal 
                visible ={upgradeInProgress}
                title="Upgrade in process"
                style={{height: 'max-content'}}
                msg={
                    <div>
                        <Spinner style={{position: 'relative'}} label={false} />
                        Please wait...
                    </div>  
                }
                cta={false}
            />
            <Modal 
                visible ={showMessage}
                title="Upgrade Successful!"
                img="https://cdn.staticcrate.com/stock-hd/graphics/print-design/proplan-illustration-01_prev_sm.2.png"
                msg="Thanks! You have properly upgraded your account. Keep downaloding great assets and making it great!"
                cta="Continue"
                style={{height: 'max-content'}}
                ctaCallback={() => document.location.href = '/member'}
            />
            { /* TODO: there's no callback for updatePayment method failure 'setup' subscription response on standalone version */}
        </div>
    );
  }

  return (
    <>
        <Header minimal={true} />
        <Signup 
            closeCallback={hideSignup} 
            signupSuccessCallback={signupSuccessCallback}
            ref={signupRef}
            visible={showSignup}
        />
        <Modal 
            visible ={!!confirmUpgrade}
            title="Confirm Your Plan Upgrade"
            msg={
                <div style={{textAlign: 'left'}}>
                    Please read to upgrade your account. Here are the details of the changes to your billing:<br />
                    {upgradeSettings && 
                    <ul style={{listStyleType: 'disc', marginTop: '12px', listStylePosition: 'inside'}}>
                        <li>You're moving from our ${upgradeSettings.from}/{`${areMonthlyPlans?'month':'year'}`} plan to the ${upgradeSettings.to}/{`${areMonthlyPlans?'month':'year'}`} plan.</li>
                    </ul>
                    }
                    <br/>
                    If you have any questions about your upgrade or billing details, please reach out to our support team <a href='mailto:support@productioncrate.com' target='_blank'>Support@ProductionCrate.com</a>
                </div>
            }
            cta="Upgrade"
            ctaCallback={() => setSelectedPlan(confirmUpgrade)}
            ctaClose="Cancel"
            ctaCloseCallback={() => setConfirmUpgrade(false)}
            style={{height: 'max-content'}}
        />
        <Modal 
            visible ={showMessage}
            title="Upgrade Successful!"
            img="https://cdn.staticcrate.com/stock-hd/graphics/print-design/proplan-illustration-01_prev_sm.2.png"
            msg="Thanks! You have properly upgraded your account. Keep downaloding great assets and making it great!"
            cta="Continue"
            ctaCallback={() => document.location.href = '/member'}
        />
        <Modal 
            visible={!!updatePaymentMethod}
            title="Upgrade failed"
            msg={
                <div style={{textAlign: 'left'}}>
                    Your payment method was declined. Please update it to <b>proceed with the upgrade</b> by following this link:<br />
                </div>
            }
            cta="Update Payment Method"
            ctaSize="small"
            ctaCallback={() => document.location.href = updatePaymentMethod}
            ctaClose="Cancel"
            ctaCloseCallback={() => setUpdatePaymentMethod(false)}
            style={{height: 'max-content'}}
        />
        
        
        <div className={`plans ${(!showLoading && !showSignup && !isAuthenticating && readyFlag>1)?'visible':''}`} ref={plansRef}>
            {userHasPlan && 
            <div className="title m-b-32 xs:m-b-16">{upgradeTitle?upgradeTitle:'Upgrade your account'}</div>
            }
            {!userHasPlan && 
            <div className={`title m-b-32 xs:m-b-16 ${!showTitle?'hidden':''}`}>Bring Hollywood home today!!</div>
            }
            {products.length>1 && areMonthlyPlans>0 && areAnnualPlans>0 && planPeriod === 'Month' && 
            <div className="subtitle m-b-8">SAVE OVER <span className="accent">${
                (12 * products[1].plans.find(p => p.periodicity_type=='Month').price)
                -
                products[1].plans.find(p => p.periodicity_type=='Year').price
                }</span> WITH A YEARLY MEMBERSHIP!</div>
            }
            {products.length>1 && areAnnualPlans>0 && planPeriod === 'Year' && !userHasPlan &&
            <div className="subtitle m-b-8">Full Pro Access for only ${products[1].plans.find(p => p.periodicity_type=='Year').price} / year</div>
            }
            {areMonthlyPlans>0 && areAnnualPlans>0 &&
            <div className="switch m-b-40" id="plan-period-switch">
                <div className="option" onClick={() => setPlanPeriod('Month')}>Monthly</div>
                <div className={`bar ${planPeriod=='Month'?'left':'right'}`} onClick={togglePlanPeriod}>
                    <div className="circle"></div>
                </div>
                <div className="option"  onClick={() => setPlanPeriod('Year')}>Yearly</div>
            </div>
            }
            <div className={`list ${products.length>0?'visible':''}`}>
            {products.length>0 && products.map((product, product_index) => (
                <React.Fragment key={product_index}>
                { product.metadata && product.selectedPlan &&
                <div className={`plan ${product.metadata && product.metadata.accent?'accent':''} ${product.hasFree?'has_free':''} `} key={product_index}>
                    {product.metadata.pretitle &&
                        <div className="pretitle m-b-8">{product.metadata.pretitle}</div>
                    }
                    <img className="pic m-b-8" alt="" src={product.metadata.img} />
                    <div className="name">{product.name}</div>
                    {areMonthlyPlans>0 && product.selectedPlan.periodicity_type==='Year' && 
                    <div className="old-price">${product.plans.find(pl => pl.periodicity_type==='Month') && Math.round(product.plans.find(pl => pl.periodicity_type==='Month' && pl.price>0).price)}/mo</div>
                    }
                    {planPeriod === 'Year' && product.selectedPlan.name.toLowerCase()==='free' && 
                    <div className="old-price">&nbsp;</div>
                    }
                    <div className="price m-b-8">
                        <div className="accent">
                            $
                            {(!showSignup && !isAuthenticating && readyFlag>1) &&
                            <AnimatedCounter
                                value={Math.ceil(product.selectedPlan.periodicity_type==='Year'?product.selectedPlan.price/12:product.selectedPlan.price)}
                                color='#f68802'
                                fontSize='40px'
                                includeDecimals={false}
                            />
                            }
                            /mo
                        </div>
                        
                    </div>
                    {product.selectedPlan.periodicity_type==='Year' && 
                    <div className="note m-b-16">billed ${product.selectedPlan.price} annually</div>
                    }
                    {product.selectedPlan.periodicity_type==='Month' && product.selectedPlan.name.toLowerCase() !== 'free' && 
                    <div className="note m-b-16">billed monthly</div>
                    }
                    {['monthly', 'free'].includes(product.selectedPlan.name.toLowerCase()) && product.hasFree && 
                    <div className="note m-b-16">optional billing</div>
                    }
                    <div className={`cta ${(userHasPlan && user && user.team.subscription.plan.id === product.selectedPlan.id)?'selected':''} m-b-36`} onClick={() => choosePlan(product.selectedPlan.id)}>
                        {userHasPlan && user && user.team.subscription.plan.id === product.selectedPlan.id &&
                            <>Current Plan</>
                        }
                        {(!user || !userHasPlan || user.team.subscription.plan.id !== product.selectedPlan.id) &&
                        <>
                        {userHasPlan?product.metadata.upgrade:product.metadata.cta}
                        </>
                        }
                    </div>
                    <div className="features">
                        {product.metadata.features && product.metadata.features.map((feature, feature_index) => (
                        <div className="item" key={`product-${product_index}-feature-${feature_index}`}>
                                {feature.check && <img src={`/images/icon.check.svg`} alt="check icon" />}
                                {feature.variable && 
                                    <div style={{display: 'inline-flex', gap: '4px'}}>
                                        {(!showSignup && !isAuthenticating && readyFlag>1) &&
                                        <AnimatedCounter
                                            value={product.selectedPlan.name.toLowerCase()=="free"?feature.freeValue:feature.donatorValue}
                                            color='#fefefe'
                                            fontSize='14px'
                                            includeDecimals={false}
                                        />
                                        }
                                        {feature.name}
                                    </div>
                                }
                                {!feature.variable && 
                                    <>
                                    {feature.link && 
                                    <a href={feature.link} target="_blank">{feature.name}</a>   
                                    }
                                    {!feature.link &&
                                        <>
                                            {feature.name}
                                        </>
                                    }
                                    </>
                                }
                                {feature.tooltip && 
                                    <div className="tooltip">
                                        {feature.tooltip}
                                    </div>
                                }
                            </div> 
                        ))}
                    </div>
                    {product.hasFree && product.plans.length>1 &&
                    <label className="donate" htmlFor="donate-checkbox">
                        <input type="checkbox" id="donate-checkbox" value={donatorCheck} onChange={toggleDonate} checked={donatorCheck} />
                        Donate $15/mo for 4x Downloads
                    </label>
                    }
                </div>
                }
                </React.Fragment>
            ))}
            {products.filter(p=>p.selectedPlan).length==0 &&
            <div className="m-h-a m-v-a" style={{color: '#FFF', minHeight: '500px', display: 'flex', alignItems: 'center'}}>No plans to signup to...</div>
            }
            </div>
            {showProviderSwitch && 
            <div className="switch m-b-40 m-t-48" id="plan-provider-switch">
                <div className="option" onClick={() => setProvider('paypal')}>Paypal</div>
                <div className={`bar ${provider=='paypal'?'left':'right'}`} onClick={toggleProvider}>
                    <div className="circle"></div>
                </div>
                <div className="option"  onClick={() => setProvider('stripe')}>Stripe</div>
            </div>
            }
            <div className="custom-claim m-t-32">
            <b>Looking for a custom solution?</b> 
            <a href="mailto:support@productioncrate.com">Contact us</a> 
            and we'll build a custom plan for you, tailored to your specific business needs.
            </div>
        </div>
        
        {(readyFlag<2 || showLoading) &&
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: '100vh'}}>
            <Spinner style={{position: 'relative'}} label={loadingLabel} />
        </div>
        }
        {!showSignup && !isAuthenticating && readyFlag>1 &&
        <>
            <Faq donateUncheckHandle={doNotDonate} />
            <Footer />
        </>
        }
        
        
    </>
  );
});

export default Plans;
