import { GoogleOAuthProvider } from '@react-oauth/google'
import config from './lib/config'
import { RouterProvider } from "react-router-dom";
import { UserContextProvider } from './contexts'

const Providers = ({router, children }) => {
  if (!router){
    return (
      <UserContextProvider>
        {children}
      </UserContextProvider>
    )
  }
  return (
    <UserContextProvider>
      <GoogleOAuthProvider clientId={config.googleClientId}>
          <RouterProvider router={router}>
            {children}
          </RouterProvider>
      </GoogleOAuthProvider>
    </UserContextProvider>
  )
}

export default Providers
