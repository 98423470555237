import React, {useEffect, useState, useRef, useImperativeHandle, forwardRef} from 'react';
import './Input.scss';


const Input = forwardRef(({label, options, onChange, input=false, type='text'}, ref) => {

    const inputRef = useRef(null);

    const [internalValue, internalValueSet]  = useState(!input?options[0].value:'');
    const [inputVisible, inputVisibleSet]  = useState(input);

    useImperativeHandle(ref, () => ({
        setValue(val){
            internalValueSet(val);
            //console.log("2. Internal Value set", val);
        }
    }));


    const handleChange = (event) => {
        internalValueSet(event.target.value);
    };

    useEffect(() => {
        onChange(internalValue);
        //console.log("3. onChange", internalValue);
        if(internalValue == 'other'){
            //console.log("VALUE!!", internalValue);
            inputVisibleSet(true);
            internalValueSet('');
            
        }
    }, [internalValue]);

    useEffect(() => {
        if(inputVisible){
            //inputRef.current.focus();
        }
    }, [inputVisible]);

    return (
        <label className="input-multiple">
            {label && 
            <span>{label}</span>
            }
            {!inputVisible && options && options.length > 0 && 
            <select placeholder="" value={!internalValue?'':internalValue} onChange={handleChange}>
                {options.map( (option, index) => 
                <option key={`input-${label}-${index}`} value={option.value}>{option.label}</option> 
                )}
            </select>
            }
            {inputVisible && 
                <input type={type} value={!internalValue?'':internalValue} onChange={handleChange} ref={inputRef} />
            }
        </label>
    );
});

export default Input;

