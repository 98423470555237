import React, {useState, useEffect, useRef, forwardRef} from 'react';
//import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../lib/config';
import { useUserContext } from "../../contexts";
import { providerLoginFn, meFn } from '../../lib/api'
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import './Signup.scss';
import {ButtonCTA} from '../Form';

const Signup = forwardRef(({closeCallback, signupSuccessCallback, defaultEmail, notClosable=false, visible=true}, ref) => {

    const formCTARef = useRef(null);
    //const reCaptchaRef = useRef(null);

    //Custom Hooks
    const {user, userToken, setUserToken, setRefreshToken} = useUserContext();

    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(defaultEmail?defaultEmail:'');
    const [password, setPassword] = useState('');
    const [newsletter, setNewsletter] = useState(null);
    const [errorMsg, setErrorMsg] = useState(false);

    //const [recaptchaToken, setRecaptchaToken] = useState(false);

    const handleEmailSignup = async () => {
        /*
        let localRecaptchaToken = false;
        try{
            localRecaptchaToken = await reCaptchaRef.current.executeAsync();
            

            console.log("RECAPTCHA token", localRecaptchaToken);
        }catch(e){
            console.log("RECAPTCHA ERROR", e);
            return;
        }
        */
        setErrorMsg(false);

        if(!handleEmailValidate()){
            return false;
        }
        const data = {
            "username": username,
            "email": email,
            "password": password,
            //'g-recaptcha-response': localRecaptchaToken,
        }; 
        try{ 
            const { access_token, refresh_token } = await providerLoginFn('email', data);
            setRefreshToken(refresh_token);
            setUserToken(access_token);
            //signupSuccessCallback(); 
        } catch(res) {
            console.log("error signup", res);
            setErrorMsg(res.message);
        };
        //reCaptchaRef.current.reset();
    };

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            providerLoginFn('google', tokenResponse)
            .then(({ access_token, refresh_token }) => {
                setRefreshToken(refresh_token);
                setUserToken(access_token);
                //signupSuccessCallback();
            })
        },
        onError: response => console.error(response.error_description ?? 'OAuth Error'),
    });

    const handleFacebookLogin = (response) => {
        //console.log(response)

        providerLoginFn('facebook', { access_token: response.accessToken })
            .then(({ access_token, refresh_token }) => {
                setRefreshToken(refresh_token);
                setUserToken(access_token);
                //signupSuccessCallback();
            });
    };
    const handleUsernameChange = (event) => {
        //({target}) => setUsername(target.value)
        let value = event.target.value;

        // Filtrar caracteres no permitidos
        value = value.replace(/[^a-zA-Z0-9-_]/g, '');

        // Convertir a minúsculas
        value = value.toLowerCase();

        setUsername(value);
    };
    
    const handleEmailChange = (event) => {
        //({target}) => setEmail(target.value);
        let value = event.target.value;

        // Filtrar caracteres no permitidos (puedes ajustar la regex según tus necesidades)
        value = value.replace(/[^a-zA-Z0-9@._+-]/g, '');

        // Convertir a minúsculas el email
        value = value.toLowerCase();

        setEmail(value);
    };

    const handleEmailValidate = () => {

        if(! /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
            setErrorMsg("The email field must be a valid email address.");
            return false;
        }
        setErrorMsg(false);
        return true;
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        formCTARef.current.click();
    };

    useEffect(() => {
        if(!userToken || newsletter === null){
            return;
        }
        //signup to newsletter feature is only available for email signup for now...
        meFn({newsletter: newsletter}).then(data => {
            console.log("newsletter setting", data); 
            signupSuccessCallback();
        });

    }, [userToken, newsletter]);
    return (
        <div className={`signup ${visible?'visible':''}`}  ref={ref}>
            {!defaultEmail && !notClosable && <span className="glyphicon glyphicon-plus close-btn" onClick={() => closeCallback()} ></span>}
            <form className="form" onClick={event => event.stopPropagation()} onSubmit={handleFormSubmit}>
                <input type="submit" style={{display: 'none'}} />
                <div className="step m-b-16">Step 2 of 3</div>
                <div className="title m-b-16">
                    {defaultEmail?'Register with email':'Claim an Account'}
                </div>
                {false && 
                    <>
                        <div className="input-name m-b-6">First Name</div>
                        <input className="input-text m-b-8" type="text" placeholder="First Name" onChange={({target}) => setFirstName(target.value)}  value={firstName} />
                        <div className="input-name m-b-6">Last Name</div>
                        <input className="input-text m-b-8" type="text" placeholder="Last Name" onChange={({target}) => setLastName(target.value)} value={lastName} />
                    </>
                }
                <div className="input-name m-b-6">Username</div>
                <input className="input-text m-b-8" type="text" placeholder="Username" onChange={handleUsernameChange} value={username} />
                <div className="input-name m-b-6">Email</div>
                <input className={`input-text m-b-8`} type="email" placeholder="Email" disabled={!!defaultEmail} onChange={handleEmailChange} onBlur={handleEmailValidate} value={email} />
                <div className="input-name m-b-6">Password</div>
                <input className="input-text m-b-20" type="password" placeholder="Password" onChange={({target}) => setPassword(target.value)} value={password} />
                <label className="newsletter m-b-24" htmlFor="newsletter-checkbox">
                    <input type="checkbox" checked={newsletter} onChange={() => setNewsletter(!newsletter)} value={newsletter} id="newsletter-checkbox" />
                    Subscribe to our monthly newsletter
                </label>
                {errorMsg && 
                    <div className="m-b-12 error-msg">{errorMsg}</div>
                }
                <ButtonCTA onClick={handleEmailSignup} label="Continue" labelLoading="Signing up..." ref={formCTARef} />
                {defaultEmail && 
                <div className="m-t-32 m-b-28" style={{cursor: 'pointer', textDecoration: 'underline', color: '#FFF'}} onClick={() => closeCallback()}>Go Back</div>
                }
                {!defaultEmail && 
                <div className="separator m-b-28">or</div>
                }
                {false && 
                    <FacebookLogin
                        appId={config.facebookAppId}
                        scope="email,public_profile"
                        onSuccess={handleFacebookLogin}
                        onFail={(error) => {
                            console.debug('Login Failed!', error)
                        }}
                        className="btn cta-facebook m-b-24"
                    >
                        <img className="icon" src="/images/icon.fb.svg" alt="facebook icon" />
                        Continue with Facebook
                    </FacebookLogin>
                }
                {!defaultEmail && 
                <>
                    <div
                        className="btn cta-google m-b-32"
                        onClick={() => googleLogin()}
                    >
                        <img className="icon" src="/images/icon.google.svg" alt="google icon" />
                        Continue with Google
                    </div>
                    <div className="links">
                        <a className="link" target="_blank" href="https://www.productioncrate.com/terms.html">Terms</a>
                        |
                        <a className="link" target="_blank" href="https://www.productioncrate.com/privacy.html">Privacy</a>
                    </div>
                    <div className="links">
                        <div className="link small m-t-12" onClick={() => closeCallback()} >Go Back</div>
                    </div>
                </>
                }
            </form>
            {/*
            <ReCAPTCHA
                sitekey={config.recaptchaSiteKey}
                size="invisible"
                ref={reCaptchaRef}
            />
            */}
        </div>
    );
});

export default Signup;

