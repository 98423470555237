import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../lib/config';
import { forgetPassword, resetPassword } from '../../lib/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../../styles/layout.scss';
import './PasswordRecovery.scss';
import { Header } from '../Header';
import {ButtonCTA} from '../Form';


const PasswordRecovery = ({}) => {

    const formCTARef = useRef(null);
    const reCaptchaRef = useRef(null);

    const [searchParams] = useSearchParams();
    
    const [email, setEmail] = useState('');
    const [resetToken, setResetToken] = useState(false);
    
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    useEffect(() => {
        if (reCaptchaRef && reCaptchaRef.current){
            reCaptchaRef.current.reset();
        }
    }, [reCaptchaRef]);
    useEffect(() => {
        let emailParam = searchParams.get("email");
        let tokenParam = searchParams.get("token");

        if(emailParam && tokenParam) {
            setResetToken(tokenParam);
            setEmail(emailParam);
        }

    }, [searchParams]);
    //If we found an eligible account associated with that username, we've sent password reset instructions to the primary email address on the account.
    
    const handleForgetPassword = async () => {
        let recaptchaToken;
        try{
            recaptchaToken = await reCaptchaRef.current.executeAsync();

            console.log("RECAPTCHA token", recaptchaToken);
        }catch(e){
            console.log("RECAPTCHA ERROR", e);
            return;
        }

        setErrorMsg(false);
        setSuccessMsg(false);
        

        try{
            const res = await forgetPassword({email, 'g-recaptcha-response': recaptchaToken});
            console.log("forget password success", res);
            setSuccessMsg("Reset instructions sent. Please check your email.");
            setErrorMsg(false);
        } catch (err) {
            console.log("forget password failed", err.error?err.error:err);
            setErrorMsg("No account found with that email address.");
            setSuccessMsg(false);
        }
        reCaptchaRef.current.reset();
    };

    const handleResetPassword = async () => {
        let recaptchaToken;
        try{
            recaptchaToken = await reCaptchaRef.current.executeAsync();
            console.log("RECAPTCHA token", recaptchaToken);
        }catch(e){
            console.log("RECAPTCHA ERROR", e);
            return;
        }
        setErrorMsg(false);
        setSuccessMsg(false);
        
        
        const token = resetToken;

        if(password != confirmPassword){
            setErrorMsg("Passwords do not match");
            return;
        }
        try{
            const res = await resetPassword({email, token, password, 'g-recaptcha-response': recaptchaToken});
            console.log("reset password success");
            setSuccessMsg("Password reset successfully");
            setErrorMsg(false);
        } catch (err) {
            console.log("reset password failed", err.error?err.error:err);
            setErrorMsg("Password reset failed");
            setSuccessMsg(false);
        }    
        reCaptchaRef.current.reset();
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        formCTARef.current.click();
    };

    return (
        <div className="layout">
            <Header search={() => null} />
            <div className="password-recovery" >
                {!resetToken && 
                    <form className="form" onClick={event => event.stopPropagation()} onSubmit={handleFormSubmit}>
                        <input type="submit" style={{display: "none"}} />
                        <div className="title m-b-16">Reset Password</div>
                        <div className="explanation m-b-12">To reset your password, please provide your ProductionCrate email.</div>
                        {errorMsg && 
                            <div className={`explanation error-msg m-b-12`}>{errorMsg}</div>
                        }
                        {successMsg && 
                            <div className={`explanation success-msg m-b-12`}>{successMsg}</div>
                        }
                        <input className="input-text m-b-12" type="email" placeholder="Email" onChange={({target}) => setEmail(target.value)} value={email} />
                        <ButtonCTA ref={formCTARef} label="Send Reset Instruction" labelLoading="Sending instructions..." onClick={handleForgetPassword} />
                    </form>
                }
                {resetToken &&
                    <form className="form" onClick={event => event.stopPropagation()} onSubmit={handleFormSubmit}>
                        <input type="submit" style={{display: "none"}} />
                        <div className="title m-b-16">Reset Password</div>
                        <div className="explanation m-b-12">Please enter your new password.</div>
                        {errorMsg && 
                            <div className={`explanation error-msg m-b-12`}>{errorMsg}</div>
                        }
                        {successMsg && 
                            <>
                                <div className={`explanation success-msg m-b-12`}>{successMsg}</div>
                                <div className={`explanation m-b-12`}>You can now <a href="/login">login</a> with your new password.</div>
                            </>
                        }
                        <input className="input-text m-b-12" type="password" placeholder="Password" onChange={({target}) => setPassword(target.value)} value={password} />
                        <input className="input-text m-b-12" type="password" placeholder="Confirm Password" onChange={({target}) => setConfirmPassword(target.value)} value={confirmPassword} />
                        <ButtonCTA ref={formCTARef} label="Reset Password" labelLoading="Resetting password..." onClick={handleResetPassword} />
                    </form>
                }
            </div>
            <ReCAPTCHA
                sitekey={config.recaptchaSiteKey}
                size="invisible"
                ref={reCaptchaRef}
            />
        </div>
    );
};

export default PasswordRecovery;

