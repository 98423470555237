import React, {useState, useEffect} from 'react';
import { useUserContext } from "../../contexts";
import { getAssetsConsumptions, getAssetsAvailability, consumeAssets } from '../../lib/api';
import { favoritesFn } from '../../lib/api';
import { Spinner } from '../Spinner';
import './Consumptions.scss';


const Consumptions = ({settings, settingsCallback}) => {

    const {user, reloadCurrentUser} = useUserContext();

    const [consumptions, setConsumptions] = useState([]);

    useEffect(() => {
        if(!user){
            return;
        }
        getAssetsConsumptions().then(data => {
            //console.log("assets consumptions", data);
            setConsumptions(data);
        });

    }, [user]);

    const [favorites, setFavorites] = useState([
        {id:1, title: "(4K) Magical Soul Streams 8", url: "https://vfx.productioncrate.com/video-effects/FootageCrate-4K_Magical_Soul_Streams_8"},
        {id:2, title: "(4K) Magical Soul Streams 8", url: "https://vfx.productioncrate.com/video-effects/FootageCrate-4K_Magical_Soul_Streams_8"},
        {id:3, title: "(4K) Magical Soul Streams 8", url: "https://vfx.productioncrate.com/video-effects/FootageCrate-4K_Magical_Soul_Streams_8"},
        {id:4, title: "(4K) Magical Soul Streams 8", url: "https://vfx.productioncrate.com/video-effects/FootageCrate-4K_Magical_Soul_Streams_8"}
    ]);

    const handleFavoriteClick = (item) => {
        favoritesFn({
            id: item.id,
            title: item.extra_attributes.title,
            location: item.extra_attributes.location,
        }).then(data => {
            //console.log("favorited", data);
            reloadCurrentUser();
        });
    };

    return (
        <div className="consumptions row-section">
            <div className="title m-b-8">
                <span className="glyphicon glyphicon-cloud-download" ></span>
                Assets Download History
            </div>
            <ul className="feat-list ">
                {consumptions.length > 0 && consumptions.map((item, index) => 
                    <li key={`consumption-list-${index}`}>
                        {(new Date(item.created_at)).toLocaleString("en-US")}: 
                        <a target="_blnk" className="m-r-12" href={item.extra_attributes.url}>
                            {item.extra_attributes.level != "0" &&
                                <span className="glyphicon glyphicon-star m-r-8" style={{WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundImage: 'linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%)'}}></span>
                            }
                            {item.extra_attributes.title}
                        </a>
                        {false && <a><span className="glyphicon glyphicon-thumb-up" onClick={() => handleFavoriteClick(item)}></span></a>}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Consumptions;

